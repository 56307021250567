import { Button, TableCell, TableRow, Typography } from '@mui/material';
import { InvoiceItem } from '../../slice/api/invoice';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { InvoiceItemOptionsMenu } from './InvoiceItemOptionsMenu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CreateReturnModal } from '../modal/CreateReturnModal';
import { ProductComplaintModal } from '../modal/ProductComplaintModal';

type InvoiceDetailsRowProps = {
  item: InvoiceItem;
  displayMenu?: boolean;
  canReturnItems?: boolean;
};

const rowStyle = {
  mb: -0.5,
  mt: 0,
  color: 'rgba(0,0,0,0.6)',
  display: { xs: 'block', lg: 'none' },
};

export const InvoiceDetailsRow = (props: InvoiceDetailsRowProps) => {
  const { t } = useTranslation();
  const { item, displayMenu, canReturnItems } = props;
  const [isReturnsOpen, setIsReturnsOpen] = useState(false);
  const [isProductComplaintOpen, setIsProductComplaintOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableRow key={item.productCode}>
        <TableCell component='th' scope='row'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.productCode')}
          </Typography>
          <Typography variant='body1'>{item.productCode}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.productOrService')}
          </Typography>
          <Typography variant='body1'>{item.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.quantity')}
          </Typography>
          <Typography variant='body1'>{item.quantity}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.price')}
          </Typography>
          <Typography variant='body1'>{item.totalEurCent / 100 / item.quantity}€</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            <Typography variant='overline' gutterBottom sx={rowStyle}>
              {t('purchases.total')}
            </Typography>
          </Typography>
          <Typography variant='body1'>{item.totalEurCent / 100}€</Typography>
        </TableCell>
        <TableCell sx={{ textAlign: { sx: 'left', lg: 'right' } }}>
          {displayMenu && (
            <>
              <Button
                variant='text'
                onClick={handleClick}
                aria-controls={isMenuOpen ? 'action-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={isMenuOpen ? 'true' : undefined}
                endIcon={<ExpandMoreIcon />}
              >
                {t('purchases.options')}
              </Button>

              <InvoiceItemOptionsMenu
                setIsReturnsOpen={setIsReturnsOpen}
                setIsProductComplaintOpen={setIsProductComplaintOpen}
                anchorEl={anchorEl}
                isOpen={isMenuOpen}
                canReturnItems={!!canReturnItems}
                handleClose={handleClose}
              />
            </>
          )}
        </TableCell>
      </TableRow>
      <CreateReturnModal
        isOpen={isReturnsOpen}
        setIsReturnsOpen={setIsReturnsOpen}
        invoiceId={item.invoiceId}
        invoiceItemId={item.id}
      />
      <ProductComplaintModal isOpen={isProductComplaintOpen} setIsOpen={setIsProductComplaintOpen} item={item} />
    </>
  );
};
