import { Box, Card, CardContent, Container, Tab, Tabs } from '@mui/material';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdCardAuth } from './methods/IdCardAuth';
import { LoginEmailForm } from './methods/LoginEmailForm';
import { MobileIdAuth } from './methods/MobileIdAuth';
import { SmartIdAuth } from './methods/SmartIdAuth';
import { SignupEmailForm } from './methods/SignupEmailForm';

type AuthTabsProps = {
  type: 'signup' | 'login';
};
type authMethod = 'email' | 'mobile-id' | 'smart-id' | 'id-card';
export const AuthTabs = (props: AuthTabsProps) => {
  const { t } = useTranslation();
  const [authMethod, setauthMethod] = useState<authMethod>('email');

  return (
    <Container maxWidth='sm'>
      <Card>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={authMethod}
            onChange={(e, newTab: authMethod) => setauthMethod(newTab)}
            aria-label='select strong auth method'
            variant='scrollable'
            scrollButtons='auto'
            allowScrollButtonsMobile
          >
            <Tab label={t('auth.email')} value={'email'} />
            <Tab label={t('auth.mobileId')} value={'mobile-id'} />
            <Tab label={t('auth.smartId')} value={'smart-id'} />
            <Tab label={t('auth.idCard')} value={'id-card'} />
          </Tabs>
        </Box>
        <CardContent sx={{ p: 3 }}>
          {authMethod === 'email' && props.type === 'signup' ? <SignupEmailForm /> : null}
          {authMethod === 'email' && props.type === 'login' ? <LoginEmailForm /> : null}
          {authMethod === 'mobile-id' ? <MobileIdAuth /> : null}
          {authMethod === 'smart-id' ? <SmartIdAuth /> : null}
          {authMethod === 'id-card' ? <IdCardAuth /> : null}
        </CardContent>
      </Card>
    </Container>
  );
};
