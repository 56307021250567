import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableHeadCell } from '../ordersTable/OrdersTable';
import { CompanyCustomerTableRow } from './CompanyCustomerTableRow';
import { useGetCompanyCustomersQuery } from '../../slice/api/auth';
import { LoadingBackdrop } from '../shared/LoadingBackdrop';

export const CompanyCustomerTable = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetCompanyCustomersQuery();

  const headCells: TableHeadCell[] = [
    {
      id: 'expandDetails',
      align: 'inherit',
      label: '',
    },
    {
      id: 'name',
      align: 'inherit',
      label: t('company.customerName'),
    },
    {
      id: 'email',
      align: 'inherit',
      label: t('company.customerEmail'),
    },
    {
      id: 'delete',
      align: 'inherit',
      label: '',
    },
  ];

  return (
    <>
      <Card sx={{ width: '100%', mt: { xs: 1, sm: 2, md: 4, lg: 6 } }}>
        <CardContent>
          <Typography variant='h5' sx={{ py: { xs: 1, md: 1, lg: 2 }, px: { xs: 0, md: 0, lg: 2 } }}>
            {t('company.associatedPersons')}
          </Typography>
          <TableContainer className='table-holder-responsive'>
            <Table aria-label='history table'>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align}>
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((customer, idx) => (
                  <CompanyCustomerTableRow key={customer.id} idx={idx} customer={customer} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <LoadingBackdrop isLoading={isLoading} />
        </CardContent>
      </Card>
    </>
  );
};
