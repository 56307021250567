// import logo from './logo.svg';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import Router from './Router';
import { SharedToasts } from './component/shared/SharedToasts';
import { useQuery } from './helpers/useQuery';
import { useSetRedirectURI } from './helpers/useRedirectURI';
import { useSetRedirectURIReturn } from './helpers/useRedirectURIReturn';
import { useSelectedLanguage } from './helpers/useSelectedLanguage';
import './i18n/i18n';
import store from './store/store';

export default function App() {
  const { i18n, t } = useTranslation();
  const language = useSelectedLanguage();
  const setRedirectURI = useSetRedirectURI();
  const setRedirectURIReturn = useSetRedirectURIReturn();
  const queryRedirectURI = useQuery().get('redirect_uri');
  const queryRedirectURIReturn = useQuery().get('redirect_uri_return');

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    if (queryRedirectURI) {
      setRedirectURI(queryRedirectURI);
    }
  }, [queryRedirectURI, setRedirectURI]);

  useEffect(() => {
    if (queryRedirectURIReturn) {
      setRedirectURIReturn(queryRedirectURIReturn);
    }
  }, [queryRedirectURIReturn, setRedirectURIReturn]);

  useEffect(() => {
    document.title = t('pageTitle');
  }, [language, t]);

  return (
    <Provider store={store}>
      <Router />
      <SharedToasts />
    </Provider>
  );
}
