import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormGroup } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCompleteEmailSignupMutation } from '../../../slice/api/auth';
import { CompleteEmailSignUpSchema, completeEmailSignUpSchema } from '../../../validation/schema/auth.schema';
import { LoadingBackdrop } from '../../shared/LoadingBackdrop';
import { RedirectSignedIn } from '../../shared/RedirectSignedIn';
import { SharedForm } from '../../shared/SharedForm';
import { SharedTextInput } from '../../shared/SharedTextInput';

type SignupCompleteEmailFormProps = {
  hash: string;
};

export const SignupCompleteEmailForm = (props: SignupCompleteEmailFormProps) => {
  const { t } = useTranslation();
  const [signupCompleteEmail, { isLoading, isSuccess, error }] = useCompleteEmailSignupMutation();

  const { register, handleSubmit, formState } = useForm<CompleteEmailSignUpSchema>({
    resolver: zodResolver(completeEmailSignUpSchema),
    defaultValues: {
      linkHash: '',
      firstName: '',
      lastName: '',
    },
  });

  const onSubmit = (data: CompleteEmailSignUpSchema) => signupCompleteEmail({ ...data, linkHash: props.hash });

  if (isSuccess) {
    return <RedirectSignedIn />;
  }

  return (
    <SharedForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} error={error}>
      <FormGroup>
        <SharedTextInput label={t('general.firstName')} {...register('firstName')} />
        <br />
        <SharedTextInput label={t('general.lastName')} {...register('lastName')} />
        <br />
      </FormGroup>

      <FormGroup>
        <Button variant='contained' color='primary' type='submit' disabled={!formState.isDirty}>
          {t('general.save')}
        </Button>
      </FormGroup>
      <LoadingBackdrop isLoading={isLoading} />
    </SharedForm>
  );
};
