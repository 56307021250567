import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormGroup } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { handleModalFormState } from '../../helpers/handleModalFormState';
import { mergeValidationErrors } from '../../helpers/mergeErrors';
import { useSetPasswordMutation } from '../../slice/api/auth';
import { SetPasswordSchema, setPasswordSchema } from '../../validation/schema/auth.schema';
import { SharedForm } from '../shared/SharedForm';
import { SharedTextInput } from '../shared/SharedTextInput';
import { useAddToast } from '../../slice/toast';

export const SetPasswordModal = (props: { isOpen: boolean; handleClose: () => void }) => {
  const { isOpen, handleClose } = props;
  const { t } = useTranslation();
  const [setPassword, { isLoading, error: serverError, isSuccess, reset: resetMutation }] = useSetPasswordMutation();
  const addToast = useAddToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<SetPasswordSchema>({ resolver: zodResolver(setPasswordSchema) });

  const resetAll = useCallback(() => {
    reset();
    resetMutation();
  }, [reset, resetMutation]);

  useEffect(() => {
    handleModalFormState(isSuccess, isOpen, resetAll, handleClose);
  }, [isSuccess, resetAll, handleClose, isOpen]);

  useEffect(() => {
    if (isSuccess) {
      addToast({ message: t('settings.passwordChanged'), severity: 'success' });
    }
  }, [isSuccess]);

  const onSubmit = (data: SetPasswordSchema) => setPassword(data);
  const validationErrors = mergeValidationErrors(serverError, errors, t);

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={isOpen} onClose={handleClose}>
      <SharedForm onSubmit={handleSubmit(onSubmit)} error={serverError} isLoading={isLoading}>
        <DialogTitle id='alert-dialog-title'>{t('settings.setPassword')}</DialogTitle>
        <Divider />
        <DialogContent>
          <FormGroup>
            <SharedTextInput
              isPassword={true}
              autoComplete={'newPassword'}
              label={t('settings.newPassword')}
              errorMessage={validationErrors['newPassword']}
              {...register('newPassword')}
            />
            <br />

            <SharedTextInput
              isPassword={true}
              autoComplete={'confirmNewPassword'}
              label={t('settings.confirmNewPassword')}
              errorMessage={validationErrors['newPasswordConfirmation']}
              {...register('newPasswordConfirmation')}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> {t('general.cancel')}</Button>
          <Button variant='contained' color='primary' type='submit' fullWidth={false} disabled={!isDirty}>
            {t('general.save')}
          </Button>
        </DialogActions>
      </SharedForm>
    </Dialog>
  );
};
