import { Table, TableCell, TableRow, Typography } from '@mui/material';
import { LocalNote } from '../../slice/api/order';

export const LocalNotes = (props: { localNotes?: LocalNote[] }) => {
  const { localNotes } = props;

  return (
    <Table>
      {localNotes?.map((note, idx) => {
        return (
          <TableRow key={`${idx}`}>
            <TableCell sx={{ maxWidth: 180, pl: 0, py: 1.5 }}>
              <Typography variant='body2' sx={{ color: 'rgba(0,0,0,0.6)' }}>
                {new Date(note.createdAt).toLocaleString()}
              </Typography>
            </TableCell>
            <TableCell sx={{ py: 1.5 }}>
              <Typography variant='body2'>{note.content}</Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};
