import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Card, CardContent, Modal, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLoggedInUser } from '../../helpers/useLoggedInUser';
import { InvoiceItem, useCreateComplaintMutation } from '../../slice/api/invoice';
import { CreateProductComplaintSchema, createProductComplaintSchema } from '../../validation/schema/invoice.schema';
import { LoadingBackdrop } from '../shared/LoadingBackdrop';
import { SharedForm } from '../shared/SharedForm';
import { SharedTextInput } from '../shared/SharedTextInput';
import { mergeValidationErrors } from '../../helpers/mergeErrors';

const modalStyles = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 500,
  boxShadow: 24,
};

type ProductComplaintModalProps = {
  isOpen: boolean;
  item: InvoiceItem;
  setIsOpen: (isProductComplaintOpen: boolean) => void;
};

export const ProductComplaintModal = (props: ProductComplaintModalProps) => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen, item } = props;
  const loggedInUser = useLoggedInUser();
  const [sendComplaint, { isLoading, error: createComplaintError }] = useCreateComplaintMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<CreateProductComplaintSchema>({
    resolver: zodResolver(createProductComplaintSchema),
    defaultValues: {
      invoiceId: item.invoiceId,
      invoiceItemId: item.id,
      description: '',
    },
  });

  const onSubmit = (data: CreateProductComplaintSchema) => {
    sendComplaint(data);
    reset();
    setIsOpen(false);
  };

  const validationErrors = mergeValidationErrors(createComplaintError, errors, t);

  const customerName =
    loggedInUser.type === 'private' ? `${loggedInUser.firstName} ${loggedInUser.lastName}` : loggedInUser.companyName;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyles}>
          <Card sx={{ px: { xs: 0, sm: 1, lg: 2 } }}>
            <CardContent>
              <Typography id='modal-modal-title' variant='h5' component='h2' sx={{ mt: { xs: 0, sm: 1, lg: 2 } }}>
                {t('purchases.productConcern')}
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                {t('productComplaint.paragraph1')}
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                <Trans i18nKey='productComplaint.paragraph2'>
                  paragraph 2
                  <a href='https://onoff.ee/et/content/21-jaerelteenindus' target='_blank' rel='noreferrer'></a>
                </Trans>
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                {t('productComplaint.paragraph3')}
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 1 }}>
                {t('general.name')}: {customerName}
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 1 }}>
                {t('general.email')}: {loggedInUser.email}
                <Typography id='modal-modal-description' sx={{ mt: 1 }}></Typography>
                {t('general.phoneNumber')}: {loggedInUser.phoneNumber}
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 1 }}>
                {t('purchases.product')}: {item.name}
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 1 }}>
                {t('purchases.productCode')}: {item.productCode}
              </Typography>
              <SharedForm onSubmit={handleSubmit(onSubmit)}>
                <SharedTextInput
                  sx={{ mt: 5, width: '100%' }}
                  id='outlined-multiline-static'
                  multiline
                  rows={4}
                  label={t('purchases.describeConcern')}
                  errorMessage={validationErrors['description']}
                  {...register('description')}
                />
                <Button sx={{ mt: 2, mx: 2 }} variant='text' color='secondary' onClick={() => setIsOpen(false)}>
                  {t('general.cancel')}
                </Button>
                <Button sx={{ mt: 2, mx: 2 }} variant='contained' type='submit' disabled={!isDirty}>
                  {t('general.send')}
                </Button>
              </SharedForm>
              <LoadingBackdrop isLoading={isLoading} />
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </>
  );
};
