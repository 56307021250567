import { useFormContext } from 'react-hook-form';
import { ClientError } from '../form/ClientError';
import { LoadingBackdrop } from './LoadingBackdrop';

type SharedFormProps = {
  children: React.ReactNode;
  isLoading: boolean;
  error: any;
  onSubmit: (data: any) => void;
};

export const SharedFormWithContext = (props: SharedFormProps) => {
  const { handleSubmit } = useFormContext();
  return (
    <form onSubmit={handleSubmit((data) => props.onSubmit(data))}>
      <ClientError error={props.error} />
      {props.children}
      <LoadingBackdrop isLoading={!!props.isLoading} />
    </form>
  );
};
