import z from 'zod';
import { zodValidatePhoneNumber } from './validatePhoneNum';

export const phoneNumberValidator = z.custom<string>(
  (data) => zodValidatePhoneNumber(data as string),
  'validationError.PHONE_NUM'
);

export const emailValidation = z.string().min(5).email({ message: 'validationError.EMAIL' });

export const strongPasswordValidation = z.string().min(6, { message: 'validationError.PASSWORD_INVALID_FORMAT' });
