import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Button, Grid, IconButton, Toolbar, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OnOffLogo from '../../assets/onoff-logo.svg';
import { useLoggedInFullUser } from '../../helpers/useLoggedInUser';
import { HeaderMenu } from './HeaderMenu';
import { getRedirectURIReturn, removeRedirectURIReturn } from '../../helpers/useRedirectURIReturn';
import { useMatch } from 'react-router-dom';

interface HeaderProps {
  onDrawerToggle: () => void;
}

const pageTitlesMap = new Map([
  ['/dashboard', 'nav.purchases'],
  ['/dashboard/company', 'nav.company'],
  ['/dashboard/company-add-invitation', 'nav.companyInvite'],
  ['/dashboard/settings', 'nav.accountSettings'],
]);

export const Header = (props: HeaderProps) => {
  const { t } = useTranslation();
  const { onDrawerToggle } = props;
  const user = useLoggedInFullUser();
  const matchRoute = useMatch({ path: location.pathname, end: true });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const pageTitle = pageTitlesMap.has(matchRoute?.pathname || '')
    ? (pageTitlesMap.get(matchRoute?.pathname as string) as string)
    : 'nav.selfService';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return null;
  }

  const navigateToStore = () => {
    const href = getRedirectURIReturn();
    removeRedirectURIReturn();
    window.location.href = href && href.startsWith('https://onoff.ee/') ? href : 'https://onoff.ee/';
  };

  const customerName = (
    user.loggedInAs.type === 'private'
      ? `${user.loggedInAs.firstName} ${user.loggedInAs?.lastName}`
      : user.loggedInAs.companyName
  ) as string;

  return (
    <>
      <AppBar position='sticky' elevation={0} sx={{ backgroundColor: '#F2F2F2' }}>
        <Toolbar>
          <Grid container alignItems='center'>
            <Grid sx={{ display: { sm: 'none', xs: 'flex' } }} item width='50%'>
              <IconButton aria-label='open drawer' onClick={onDrawerToggle} edge='start'>
                <MenuIcon />
              </IconButton>
              <img src={OnOffLogo} style={{ width: 100, marginLeft: 10 }} alt='OnOff logo' />
            </Grid>
            <Grid item sx={{ ml: 'auto' }}>
              <Button
                variant='text'
                onClick={handleClick}
                aria-controls={isMenuOpen ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={isMenuOpen ? 'true' : undefined}
                endIcon={<ExpandMoreIcon />}
              >
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: { xs: 80, sm: 200, md: '100%' },
                  }}
                >
                  {customerName}
                </Box>
                <Avatar sx={{ ml: 1 }}>{customerName[0].toUpperCase() ?? ''}</Avatar>
              </Button>
              <HeaderMenu anchorEl={anchorEl} isOpen={isMenuOpen} handleClose={handleClose} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component='div'
        position='static'
        elevation={0}
        sx={{ zIndex: 0, backgroundColor: '#F2F2F2', borderBottom: 1, borderColor: 'rgba(0,0,0,0.1)' }}
      >
        <Toolbar>
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs sx={{ py: 3 }}>
              <Typography color={'#000'} variant='h5' component='h1'>
                {t(pageTitle)}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant='text' color='secondary' onClick={() => navigateToStore()} endIcon={<ArrowOutwardIcon />}>
                {t('nav.backToStore')}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
