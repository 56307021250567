import { useState } from 'react';

export const getLocalStorageItem = <T>(itemKey: string) => {
  if (typeof window === 'undefined') {
    return null;
  }

  try {
    const item = window.localStorage.getItem(itemKey);
    return item ? (JSON.parse(item) as T) : null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const removeLocalStorageItem = (itemKey: string) => {
  if (typeof window === 'undefined') {
    return null;
  }
  window.localStorage.removeItem(itemKey);
};

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
};
