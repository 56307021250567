import { createListenerMiddleware } from '@reduxjs/toolkit';
import { authApiSlice } from '../slice/api/auth';
import { getRedirectURI, removeRedirectURI } from '../helpers/useRedirectURI';

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: authApiSlice.endpoints.getMyFullUser.matchFulfilled,
  effect: async (action, listenerApi) => {
    // user logged in
    if (action.payload) {
      const redirectURI = getRedirectURI();

      if (redirectURI && redirectURI.startsWith('https://onoff.ee')) {
        removeRedirectURI();
        window.location.href = redirectURI;
        return;
      }

      // Can cancel other running instances
      listenerApi.cancelActiveListeners();
    }
  },
});
