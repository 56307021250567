import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompleteStrongAuthSignupMutation } from '../../../slice/api/auth';
import { RedirectSignedIn } from '../../shared/RedirectSignedIn';
import { ClientError } from '../ClientError';
import { Container } from '@mui/material';
import { LoadingBackdrop } from '../../shared/LoadingBackdrop';

export const CompleteStrongAuthSignup = ({ linkHash }: { linkHash: string }) => {
  const { t } = useTranslation();
  const [completeSignup, { error, isSuccess, isLoading }] = useCompleteStrongAuthSignupMutation();

  useEffect(() => {
    const { abort } = completeSignup({ linkHash: linkHash });
    return () => abort();
  }, [completeSignup, linkHash]);

  if (isSuccess) {
    return <RedirectSignedIn />;
  }

  if (error) {
    return (
      <Container maxWidth='sm'>
        <ClientError error={error} />
      </Container>
    );
  }

  return (
    <Container maxWidth='sm'>
      {t('auth.Waiting for response')}
      <LoadingBackdrop isLoading={isLoading} />
    </Container>
  );
};
