import { ListItemButton, ListItemIcon } from '@mui/material';
import { useMatch } from 'react-router-dom';

export const ActiveRoute = ({
  link,
  location,
  to,
  children,
  listItem,
  listItemActive,
}: {
  link: any;
  location: any;
  to: string;
  children: any;
  listItem: any;
  listItemActive: any;
}) => {
  const matchRoute = useMatch({ path: location.pathname, end: true });
  const menuItemStyle = { ...listItem };
  const selectedMenuItemStyle = { ...listItem, ...listItemActive };

  const style = matchRoute?.pathname === to ? selectedMenuItemStyle : menuItemStyle;

  return (
    <ListItemButton component={link} to={to} sx={style}>
      <ListItemIcon sx={{ color: 'inherit' }}>{children[0]}</ListItemIcon>
      {children[1]}
    </ListItemButton>
  );
};
