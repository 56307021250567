import { useEffect } from 'react';
import { LoggedInState, useGetMyFullUserQuery } from '../slice/api/auth';
import { useLocalStorage } from './useLocalStorage';

export const useLoggedInFullUser = () => {
  const { data, isLoading } = useGetMyFullUserQuery();
  const [user, setUser] = useLocalStorage<LoggedInState | ''>('logged-in-user', data?.loggedInAs?.id ? data : '');

  useEffect(() => {
    if (!isLoading) {
      setUser(data?.loggedInAs?.id ? data : '');
    }
  }, [data, setUser, isLoading]);

  return user === '' ? undefined : user;
};

export const useLoggedInUser = () => {
  const fullUser = useLoggedInFullUser();

  if (!fullUser) {
    throw new Error('User not logged in');
  }

  return fullUser.loggedInAs;
};
