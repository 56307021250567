import z from 'zod';

export const createProductComplaintSchema = z.object({
  invoiceId: z.number(),
  invoiceItemId: z.number(),
  description: z.string().min(2, { message: 'validationError.CONTENT' }),
});
export type CreateProductComplaintSchema = z.infer<typeof createProductComplaintSchema>;

export const createReturnProductSchema = z.object({
  returnLocation: z.string().min(2, { message: 'validationError.RETURN_LOCATION' }),
  returnMethod: z.string().min(2, { message: 'validationError.RETURN_METHOD' }),
  reason: z.string().min(2, { message: 'validationError.CONTENT' }),
  invoiceId: z.number(),
  invoiceItemId: z.number(),
});
export type CreateReturnProductSchema = z.infer<typeof createReturnProductSchema>;
