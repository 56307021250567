import phoneUtil from 'google-libphonenumber';
import { isProd } from '../config';

const phoneUtilInstance = phoneUtil.PhoneNumberUtil.getInstance();

export function zodValidatePhoneNumber(number: string) {
  try {
    if (!isProd) {
      return true;
    }
    const parseNumber = phoneUtilInstance.parseAndKeepRawInput(number, 'EE');
    const check = phoneUtilInstance.isValidNumber(parseNumber);
    return check;
  } catch (e) {
    return false;
  }
}
