import { Card, CardContent, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../helpers/useQuery';
import { ForgotPasswordForm } from '../component/form/auth/ForgotPasswordForm';

export const ForgotPasswordScreen = () => {
  const { t } = useTranslation();
  const query = useQuery();

  const email = query.get('email') as string;

  return (
    <Container style={{ display: 'flex' }}>
      <Container maxWidth='sm'>
        <Card>
          <CardContent sx={{ p: 3 }}>
            <Typography variant='h5'>{t('auth.forgotPassword')}</Typography>
            <Container style={{ display: 'flex' }}>
              <ForgotPasswordForm defaultEmail={email} />
            </Container>
          </CardContent>
        </Card>
      </Container>
    </Container>
  );
};
