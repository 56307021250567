import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetSyncTimeQuery } from '../../slice/api/order';

export const LastSynced = () => {
  const { t } = useTranslation();
  const { data } = useGetSyncTimeQuery();

  if (!data) {
    return null;
  }

  return (
    <>
      <br />
      <Typography align={'center'} display='block' variant={'button'}>
        {t('purchases.lastSyncTime', { time: new Date(data).toLocaleString() })}
      </Typography>
    </>
  );
};
