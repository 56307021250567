import { zodResolver } from '@hookform/resolvers/zod';
import { Button, ButtonGroup, FormGroup, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { mergeValidationErrors } from '../../../../helpers/mergeErrors';
import { useLoginEmailMutation } from '../../../../slice/api/auth';
import { EmailLogInSchema, emailLogInSchema } from '../../../../validation/schema/auth.schema';
import { SharedForm } from '../../../shared/SharedForm';
import { SharedTextInput } from '../../../shared/SharedTextInput';

export const LoginEmailForm = () => {
  const { t } = useTranslation();
  const [loginEmail, { isLoading, error: emailLoginError, isSuccess }] = useLoginEmailMutation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EmailLogInSchema>({
    resolver: zodResolver(emailLogInSchema),
  });

  const onSubmit = (data: EmailLogInSchema) => loginEmail(data);

  if (isSuccess) {
    return <Navigate to='/dashboard' />;
  }

  const validationErrors = mergeValidationErrors(emailLoginError, errors, t);
  const forgotPasswordPath = `/forgot-password?email=${watch('email') ?? ''}`;
  return (
    <>
      <Typography variant='h5'>{t('auth.login')}</Typography>
      <br />
      <SharedForm onSubmit={handleSubmit(onSubmit)} error={emailLoginError} isLoading={isLoading}>
        <FormGroup>
          <SharedTextInput label={t('general.email')} errorMessage={validationErrors['email']} {...register('email')} />
          <br />

          <SharedTextInput
            label={t('auth.password')}
            isPassword={true}
            errorMessage={validationErrors['password']}
            {...register('password')}
          />
        </FormGroup>

        <FormGroup sx={{ mt: 3 }}>
          <Button variant='contained' color='primary' type='submit'>
            {t('auth.login')}
          </Button>
        </FormGroup>
      </SharedForm>
      <ButtonGroup>
        <Button component={Link} variant='text' to={forgotPasswordPath}>
          {t('auth.forgotPassword')}
        </Button>
      </ButtonGroup>
    </>
  );
};
