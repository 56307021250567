import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Card, CardContent, Container, FormGroup, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeValidationErrors } from '../../../helpers/mergeErrors';
import { useCompanyInviteMutation } from '../../../slice/api/auth';
import { CompanyInviteSchema, companyInviteSchema } from '../../../validation/schema/auth.schema';
import { LoadingBackdrop } from '../../shared/LoadingBackdrop';
import { SharedForm } from '../../shared/SharedForm';
import { SharedTextInput } from '../../shared/SharedTextInput';

export const CompanyInviteForm = () => {
  const { t } = useTranslation();
  const [inviteToCompany, { isSuccess, isLoading, error: companyInviteError, reset }] = useCompanyInviteMutation();

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors, isDirty },
  } = useForm<CompanyInviteSchema>({
    resolver: zodResolver(companyInviteSchema),
  });

  const onSubmit = (data: CompanyInviteSchema) => inviteToCompany(data);

  const validationErrors = mergeValidationErrors(companyInviteError, errors, t);

  if (isSuccess) {
    return (
      <Container maxWidth='sm'>
        {t('company.A verification e-mail was sent to the company email')}
        <Button
          onClick={() => {
            reset();
            resetForm();
          }}
        >
          {t('company.clear')}
        </Button>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth='sm'>
        <Card>
          <CardContent>
            <Typography variant='h5' sx={{ mb: 3 }}>
              {t('settings.myAccount')}
            </Typography>
            <Typography variant='body1' sx={{ mb: 4 }}>
              {t('settings.addAccountDescription')}
            </Typography>
            <SharedForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} error={companyInviteError}>
              <FormGroup>
                <SharedTextInput
                  label={t('general.regCode')}
                  errorMessage={validationErrors['regCode']}
                  {...register('regCode')}
                />
                <br />
                <Button variant='contained' color='primary' type='submit' disabled={isLoading || !isDirty}>
                  {t('general.requestAccess')}
                </Button>
              </FormGroup>
            </SharedForm>
          </CardContent>
          <LoadingBackdrop isLoading={isLoading} />
        </Card>
      </Container>
    </>
  );
};
