import { List } from '@mui/material';
import { Address, useGetAddressesQuery } from '../../../slice/api/address';
import { AddressRow } from './AddressRow';
import { LoadingBackdrop } from '../../shared/LoadingBackdrop';

type CurrentAddressesProps = {
  selectedAddress?: Address;
  setSelectedAddress: (address?: Address) => void;
};

export const CurrentAddresses = (props: CurrentAddressesProps) => {
  const { data: addresses, isLoading } = useGetAddressesQuery();
  const { selectedAddress, setSelectedAddress } = props;

  if (!addresses) {
    return null;
  }

  return (
    <>
      <List>
        {addresses?.map((address) => (
          <AddressRow
            key={address.id}
            address={address}
            isSelected={selectedAddress?.id === address.id}
            setSelectedAddress={setSelectedAddress}
          />
        ))}
        <LoadingBackdrop isLoading={isLoading} />
      </List>
    </>
  );
};
