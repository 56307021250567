import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Card, CardContent, FormGroup, Modal, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RETURN_LOCATIONS, RETURN_POLICY_DAYS } from '../../config';
import { handleModalFormState } from '../../helpers/handleModalFormState';
import { mergeValidationErrors } from '../../helpers/mergeErrors';
import { useReturnProductMutation } from '../../slice/api/invoice';
import { CreateReturnProductSchema, createReturnProductSchema } from '../../validation/schema/invoice.schema';
import { SharedForm } from '../shared/SharedForm';
import { SharedSelect } from '../shared/SharedSelect';
import { SharedTextInput } from '../shared/SharedTextInput';

const modalStyles = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 500,
  boxShadow: 24,
};

type ReturnModalProps = {
  isOpen: boolean;
  setIsReturnsOpen: (isOpen: boolean) => void;
  invoiceId: number;
  invoiceItemId: number;
};

const RETURN_METHODS = ['poodi', 'kulleriga'];
export const CreateReturnModal = (props: ReturnModalProps) => {
  const { t } = useTranslation();
  const { isOpen, setIsReturnsOpen, invoiceId, invoiceItemId } = props;
  const [returnProduct, { error: serverError, isLoading, isSuccess, reset: resetMutation }] =
    useReturnProductMutation();
  const handleCloseModal = useCallback(() => {
    setIsReturnsOpen(false);
  }, [setIsReturnsOpen]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<CreateReturnProductSchema>({
    resolver: zodResolver(createReturnProductSchema),
    defaultValues: {
      returnLocation: undefined,
      returnMethod: undefined,
      reason: '',
      invoiceId,
      invoiceItemId,
    },
  });

  const resetAll = useCallback(() => {
    reset();
    resetMutation();
  }, [reset, resetMutation]);

  useEffect(() => {
    handleModalFormState(isSuccess, isOpen, resetAll, handleCloseModal);
  }, [isSuccess, resetAll, handleCloseModal, isOpen]);

  const onSubmit = (data: CreateReturnProductSchema) => returnProduct(data);

  const validationErrors = mergeValidationErrors(serverError, errors, t);
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyles}>
          <Card sx={{ px: { xs: 0, sm: 1, lg: 2 } }}>
            <CardContent>
              <Typography id='modal-modal-title' variant='h5' component='h2' sx={{ mt: { xs: 0, sm: 1, lg: 2 } }}>
                {t('purchases.createReturn')}
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                {t('returns.paragraph1', { days: RETURN_POLICY_DAYS })}
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                {t('returns.paragraph2')}
              </Typography>

              <SharedForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} error={serverError}>
                <FormGroup>
                  <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                    {t('returns.paragraph3')}
                  </Typography>
                  <br />

                  <SharedSelect
                    name={'returnLocation'}
                    label={t('purchases.returnLocation')}
                    items={RETURN_LOCATIONS}
                    register={register}
                    errors={validationErrors}
                    defaultValue={undefined}
                  />
                  <br />

                  <SharedSelect
                    name={'returnMethod'}
                    label={t('purchases.returnType')}
                    items={RETURN_METHODS}
                    itemLabels={RETURN_METHODS.map((method) => t(`returns.${method}`))}
                    register={register}
                    errors={validationErrors}
                    defaultValue={undefined}
                  />
                </FormGroup>

                <SharedTextInput
                  sx={{ mt: 5, width: '100%' }}
                  id='outlined-multiline-static'
                  multiline
                  rows={4}
                  label={t('purchases.returnReason')}
                  errorMessage={validationErrors['reason']}
                  {...register('reason')}
                />

                <Button sx={{ mt: 2, mx: 2 }} variant='text' color='secondary' onClick={handleCloseModal}>
                  {t('general.cancel')}
                </Button>
                <Button sx={{ mt: 2, mx: 2 }} variant='contained' type='submit' disabled={!isDirty}>
                  {t('general.send')}
                </Button>
              </SharedForm>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </>
  );
};
