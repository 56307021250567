import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../config';
import { CreateAddressSchema, UpdateAddressSchema } from '../../validation/schema/address.schema';
import { getLanguage } from '../../helpers/useSelectedLanguage';

export interface Address {
  id: number;
  customerId: number;
  streetAddress: string;
  city: string;
  postalCode: string;
  country: string;
  isPreferredAddress: boolean;
}

export const addressApiSlice = createApi({
  reducerPath: 'addressApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/address`,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set('x-selected-language', getLanguage());
      return headers;
    },
  }),
  tagTypes: ['Address'],
  endpoints: (builder) => ({
    getAddresses: builder.query<Address[], void>({
      query: () => '/',
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Address' as const, id })), { type: 'Address', id: 'LIST' }]
          : [{ type: 'Address', id: 'LIST' }],
    }),
    createNewAddress: builder.mutation<Address, CreateAddressSchema>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Address', id: 'LIST' }],
    }),
    updateAddress: builder.mutation<Address, UpdateAddressSchema & { id: number }>({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: 'Address', id: 'LIST' }],
    }),
    deleteAddress: builder.mutation<number, { id: number }>({
      query: (data) => {
        const { id } = data;
        return {
          url: `/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Address', id: 'LIST' }],
    }),
  }),
});

export const { useGetAddressesQuery, useCreateNewAddressMutation, useUpdateAddressMutation, useDeleteAddressMutation } =
  addressApiSlice;
