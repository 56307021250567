/* eslint-disable react/no-unescaped-entities */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDeleteCustomerMutation } from '../../slice/api/auth';
import { LoadingBackdrop } from '../shared/LoadingBackdrop';
import { useEffect } from 'react';

export const DeleteAccountModal = (props: { isOpen: boolean; handleClose: () => void }) => {
  const { isOpen, handleClose } = props;
  const { t } = useTranslation();

  const [deleteCustomer, { isLoading, isSuccess }] = useDeleteCustomerMutation();

  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [isSuccess]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{t('settings.deleteAccount')}</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant='body1'>{t('settings.deleteAccountConfimation')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('general.cancel')}</Button>
        <Button
          variant='contained'
          color='error'
          onClick={() => {
            deleteCustomer();
          }}
        >
          {t('general.confirm')}
        </Button>
      </DialogActions>
      <LoadingBackdrop isLoading={isLoading} />
    </Dialog>
  );
};
