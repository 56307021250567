import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Box, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation } from 'react-router-dom';
import OnOffLogo from '../../assets/onoff-logo.svg';
import { useLoggedInUser } from '../../helpers/useLoggedInUser';
import { getRedirectURIReturn, removeRedirectURIReturn } from '../../helpers/useRedirectURIReturn';
import { useLogoutMutation } from '../../slice/api/auth';
import { LoadingBackdrop } from '../shared/LoadingBackdrop';
import { SelectLanguage } from '../shared/SelectLanguage';
import { ActiveRoute } from './ActiveRoute';

const listItem = {
  py: 2,
  px: 3,
  color: 'rgba(0, 0, 0, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(0, 0, 0, 0.08)',
    color: 'rgba(0, 0, 0, 1)',
  },
};

//TODO refactor
const listItemActive = {
  py: 2,
  px: 3,
  boxShadow: '4px 0 0 #E60003 inset',
  color: 'rgba(0, 0, 0, 1)',
  bgcolor: 'rgba(0, 0, 0, 0.04)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(0, 0, 0, 0.08)',
    color: 'rgba(0, 0, 0, 1)',
  },
};
const listItemBottom = {
  py: 1,
  px: 3,
  color: 'rgba(0, 0, 0, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(0, 0, 0, 0.08)',
    color: 'rgba(0, 0, 0, 1)',
  },
};

export const Navigator = (props: { drawerProps: DrawerProps }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const loggedInAs = useLoggedInUser();

  const isCompany = loggedInAs.type === 'business';

  const [logout, { isLoading: isLogoutLoading, isSuccess: isSuccessLogout }] = useLogoutMutation();

  const handleLogout = () => {
    logout();
  };
  if (isSuccessLogout) {
    return <Navigate to='/login' />;
  }

  const navigateToStore = () => {
    const href = getRedirectURIReturn();
    removeRedirectURIReturn();
    window.location.href = href && href.startsWith('https://onoff.ee/') ? href : 'https://onoff.ee/';
  };

  return (
    <>
      <Drawer variant='permanent' {...props.drawerProps}>
        <List disablePadding>
          <ListItem
            sx={{
              py: 4,
              px: 3,
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <img src={OnOffLogo} style={{ width: '100%' }} alt='OnOff logo' />
            <ListItemText
              sx={{
                mt: 1,
                textTransform: 'uppercase',
              }}
            >
              {t('nav.selfService')}
            </ListItemText>
          </ListItem>
          <ActiveRoute
            link={Link}
            location={location}
            to={'/dashboard'}
            listItem={listItem}
            listItemActive={listItemActive}
          >
            <ShoppingBasketIcon />
            <ListItemText sx={{ color: 'inherit' }}>{t('nav.purchases')}</ListItemText>
          </ActiveRoute>
          {/*<ActiveRoute link={Link} location={location} to={'/'} listItem={listItem} listItemActive={listItemActive}>
            <PeopleIcon />
            <ListItemText sx={{ color: 'inherit' }}>{t('nav.customerLoyaltyBenefits')}</ListItemText>
          </ActiveRoute>*/}
          {isCompany ? (
            <ActiveRoute
              link={Link}
              location={location}
              to={'/dashboard/company'}
              listItem={listItem}
              listItemActive={listItemActive}
            >
              <PeopleIcon />
              <ListItemText sx={{ color: 'inherit' }}>{t('nav.company')}</ListItemText>
            </ActiveRoute>
          ) : (
            <ActiveRoute
              link={Link}
              location={location}
              to={'/dashboard/company-add-invitation'}
              listItem={listItem}
              listItemActive={listItemActive}
            >
              <PeopleIcon />
              <ListItemText sx={{ color: 'inherit' }}>{t('nav.companyInvite')}</ListItemText>
            </ActiveRoute>
          )}

          {/*<ActiveRoute link={Link} location={location} to={'/'} listItem={listItem} listItemActive={listItemActive}>
            <TaskIcon />
            <ListItemText sx={{ color: 'inherit' }}>{t('nav.guarantee')}</ListItemText>
          </ActiveRoute>*/}
          <Divider sx={{}} />
          <ActiveRoute
            link={Link}
            location={location}
            to={'/dashboard/settings'}
            listItem={listItem}
            listItemActive={listItemActive}
          >
            <SettingsApplicationsIcon />
            <ListItemText sx={{ color: 'inherit' }}>{t('nav.accountSettings')}</ListItemText>
          </ActiveRoute>
        </List>

        <Box sx={{ marginTop: 'auto', mb: 2 }}>
          <Divider sx={{ mb: 1 }} />
          <List disablePadding>
            <ListItemButton component='a' href='mailto:abi@onoff.ee' sx={listItemBottom}>
              <EmailIcon sx={{ ml: 0.5, mr: 2 }} />
              <ListItemText sx={{ color: 'rgba(0,0,0,0.6)' }}>abi@onoff.ee</ListItemText>
            </ListItemButton>
            <ListItemButton component='a' href='tel:+3726621155' sx={listItemBottom}>
              <LocalPhoneIcon sx={{ ml: 0.5, mr: 2 }} />
              <ListItemText sx={{ color: 'rgba(0,0,0,0.6)' }}>+372 662 1155</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => navigateToStore()} sx={listItemBottom}>
              <ArrowOutwardIcon sx={{ ml: 0.5, mr: 2 }} />
              <ListItemText sx={{ color: 'inherit' }}>{t('nav.backToStore')}</ListItemText>
            </ListItemButton>
            <ListItemButton component='a' onClick={handleLogout} sx={listItemBottom}>
              <LogoutIcon sx={{ ml: 0.5, mr: 2 }} />
              <ListItemText sx={{ color: 'inherit' }}>{t('nav.logout')}</ListItemText>
            </ListItemButton>
            <ListItem sx={{ py: 1, px: 3 }}>
              <SelectLanguage />
            </ListItem>
          </List>
        </Box>
        <LoadingBackdrop isLoading={isLogoutLoading} />
      </Drawer>
    </>
  );
};
