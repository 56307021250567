import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

export interface Toast {
  id: number;
  message: string;
  severity?: 'info' | 'success' | 'error' | 'warning';
  autoHideDuration?: number;
  anchorOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'center' | 'left' | 'right' };
}

interface ToastState {
  toasts: Toast[];
}

let lastId = 0;

const generateId = () => {
  lastId++;
  return lastId;
};

const initialState = { toasts: [] } as ToastState;

export const toastSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    removeToast(state, action: PayloadAction<number>) {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },
    addToast(state, action: PayloadAction<Omit<Toast, 'id'>>) {
      state.toasts = [...state.toasts, { ...action.payload, id: generateId() }];
    },
  },
});

export const { removeToast, addToast } = toastSlice.actions;

export const useToasts = () => {
  const toasts = useSelector((state: { toasts: ToastState }) => state.toasts.toasts);

  return toasts;
};

export const useAddToast = () => {
  const dispatch = useDispatch();

  return (toast: Omit<Toast, 'id'>) => {
    dispatch(addToast(toast));
  };
};

export const useRemoveToast = () => {
  const dispatch = useDispatch();

  return (toast: Pick<Toast, 'id'>) => {
    dispatch(removeToast(toast.id));
  };
};
