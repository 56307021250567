import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PaginationQuery } from '../../component/shared/Pagination';
import { API_URL } from '../../config';
import { getLanguage } from '../../helpers/useSelectedLanguage';
import { CreateProductComplaintSchema, CreateReturnProductSchema } from '../../validation/schema/invoice.schema';

export interface Invoice {
  id: number;
  erplyId: number;
  date: number;
  invoiceNumber: string;
  totalEurCent: number;
  notes: string;
  items: InvoiceItem[];
}
export interface InvoiceItem {
  id: number;
  erplyId: number;
  invoiceId: number;
  name: string;
  productCode: string;
  quantity: number;
  totalEurCent: number;
}

export const invoiceApiSlice = createApi({
  reducerPath: 'invoiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/invoice`,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set('x-selected-language', getLanguage());
      return headers;
    },
  }),
  tagTypes: ['Invoice'],
  endpoints: (builder) => ({
    getInvoices: builder.query<{ count: number; invoices: Invoice[] }, PaginationQuery>({
      query(arg: PaginationQuery) {
        const { page, limit } = arg;
        return {
          url: `/invoices`,
          method: 'GET',
          params: { page, limit },
        };
      },
      providesTags: (result) =>
        result
          ? [...result.invoices.map(({ id }) => ({ type: 'Invoice' as const, id })), { type: 'Invoice', id: 'LIST' }]
          : [{ type: 'Invoice', id: 'LIST' }],
    }),
    sendToEmail: builder.query({
      query(arg) {
        const { erplyId, language } = arg;
        return {
          url: `/send`,
          method: 'POST',
          params: { erplyId, language },
        };
      },
    }),
    createComplaint: builder.mutation<{}, CreateProductComplaintSchema>({
      query: (body) => ({
        url: '/complaint',
        method: 'POST',
        body,
      }),
    }),
    returnProduct: builder.mutation<{}, CreateReturnProductSchema>({
      query: (body) => ({
        url: '/return-product',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetInvoicesQuery, useSendToEmailQuery, useCreateComplaintMutation, useReturnProductMutation } =
  invoiceApiSlice;
