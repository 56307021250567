import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from '../../slice/api/invoice';
import { InvoiceDetails } from './InvoiceDetails';
import { InvoiceOptionsMenu } from './InvoiceOptionsMenu';

type HistoryTableRowProps = {
  invoice: Invoice;
};

const rowStyle = {
  mb: -0.5,
  mt: 0,
  color: 'rgba(0,0,0,0.6)',
  display: { xs: 'block', lg: 'none' },
};

export const HistoryTableRow = (props: HistoryTableRowProps) => {
  const { t } = useTranslation();
  const { invoice } = props;

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const numberOfItems = invoice.items?.length ?? 0;

  return (
    <>
      <TableRow sx={{}}>
        <TableCell component='th' scope='row'>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setIsDetailsOpen(!isDetailsOpen)}
            sx={{ display: { xs: 'none', lg: 'block' } }}
          >
            {isDetailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='inherit'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.invoiceNumber')}
          </Typography>
          <Typography variant='body1'>{invoice.invoiceNumber ?? '-'}</Typography>
        </TableCell>
        <TableCell align='inherit'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.quantity')}
          </Typography>
          <Typography variant='body1'>{numberOfItems}</Typography>
        </TableCell>
        <TableCell align='inherit'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.total')}
          </Typography>
          <Typography variant='body1'>{invoice.totalEurCent / 100}€</Typography>
        </TableCell>
        <TableCell align='inherit'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.date')}
          </Typography>
          <Typography variant='body1'>{new Date(invoice.date).toLocaleDateString()}</Typography>
        </TableCell>
        <TableCell align='left'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.note')}
          </Typography>
          <Typography variant='body1'>{invoice.notes ?? '-'}</Typography>
        </TableCell>
        <TableCell sx={{ textAlign: { sx: 'left', lg: 'right' } }}>
          <Button
            variant='text'
            onClick={handleClick}
            aria-controls={isMenuOpen ? 'action-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={isMenuOpen ? 'true' : undefined}
            endIcon={<ExpandMoreIcon />}
          >
            {t('purchases.options')}
          </Button>
          <InvoiceOptionsMenu
            anchorEl={anchorEl}
            erplyId={invoice.erplyId}
            isOpen={isMenuOpen}
            handleClose={handleClose}
          />
        </TableCell>
        <TableCell align='center' sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Button variant='contained' color='primary' onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
            {t('purchases.viewDetails')}
          </Button>
        </TableCell>
      </TableRow>
      <InvoiceDetails isOpen={isDetailsOpen} invoice={invoice} />
    </>
  );
};
