export const handleModalFormState = (
  isSubmitSuccessful: boolean,
  open: boolean,
  reset: () => void,
  handleClose: () => void
) => {
  if (isSubmitSuccessful) {
    reset();
    handleClose();
  }
  if (!open) {
    reset();
  }
};
