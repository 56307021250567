import { getLocalStorageItem, useLocalStorage } from './useLocalStorage';

export const LANGUAGE = ['et', 'en', 'ru'] as const;
export type Language = (typeof LANGUAGE)[number];

export const getLanguage = () => getLocalStorageItem<Language>('selected-language') ?? 'et';

export const useSelectedLanguageControl = () => {
  return useLocalStorage<Language>('selected-language', 'et');
};

export const useSelectedLanguage = () => {
  const [language] = useSelectedLanguageControl();

  return language;
};
