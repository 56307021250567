import { Language, useSelectedLanguageControl } from '../../helpers/useSelectedLanguage';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem, FormControl } from '@mui/material';

export const SelectLanguage = () => {
  const [language, setLanguage] = useSelectedLanguageControl();

  const changeLanguage = (language: Language) => {
    setLanguage(language);
    window.location.reload();
  };

  const handleChange = (event: SelectChangeEvent) => {
    changeLanguage(event.target.value as Language);
  };

  return (
    <FormControl sx={{ minWidth: 120 }} size='small'>
      <Select
        sx={{ backgroundColor: 'rgba(255,255,255,1)' }}
        id='demo-select-small'
        value={language}
        onChange={handleChange}
      >
        <MenuItem value={'et'}>Eesti</MenuItem>
        <MenuItem value={'en'}>Inglise</MenuItem>
        <MenuItem value={'ru'}>Vene</MenuItem>
      </Select>
    </FormControl>
  );
};
