import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { TFunction } from 'i18next';
import { FieldErrors } from 'react-hook-form';

type ServerError = SerializedError | FetchBaseQueryError | undefined;

type ServerValidationErrorType<ValidationKeys extends string> = {
  status: number;
  data: {
    validationErrors: Partial<Record<ValidationKeys, string>>;
  };
};

const checkForValidationError = <SchemaKey extends keyof Record<string, any>>(error: ServerError) => {
  if (!error || !('status' in error) || !('data' in error)) {
    return false;
  }

  if (
    error.status !== 400 ||
    !error.data ||
    !('validationErrors' in (error.data as ServerValidationErrorType<SchemaKey>))
  ) {
    return false;
  }

  return error as ServerValidationErrorType<SchemaKey>;
};

export const mergeValidationErrors = <
  SchemaType extends Record<string, any>,
  SchemaKey extends Extract<keyof SchemaType, string>
>(
  serverError: ServerError,
  clientErrors: FieldErrors<SchemaType>,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  const serverValidationError = checkForValidationError<SchemaKey>(serverError);
  const serverValidationErrorMessages = serverValidationError
    ? serverValidationError.data.validationErrors
    : ({} as SchemaType);

  const keys = [
    ...(Object.keys(serverValidationErrorMessages || {}) as SchemaKey[]),
    ...(Object.keys(clientErrors || {}) as SchemaKey[]),
  ];

  const errorMap = {};
  const mappedErrors = keys.reduce((errorMap, key) => {
    const clientError =
      clientErrors && typeof clientErrors === 'object' && typeof clientErrors[key] !== 'undefined' && clientErrors[key]
        ? clientErrors[key]?.message
        : '';
    const serverError =
      serverValidationErrorMessages &&
      typeof serverValidationErrorMessages === 'object' &&
      typeof serverValidationErrorMessages[key] === 'string'
        ? `validationError.${serverValidationErrorMessages[key]}`
        : undefined;
    errorMap[key] = t(`${serverError ?? clientError ?? ''}`);
    return errorMap;
  }, errorMap as Record<any, any>);

  return mappedErrors as Partial<Record<keyof SchemaType, string>>;
};
