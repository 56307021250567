import { Card, CardContent, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SignupCompleteEmailForm } from '../component/form/auth/SignupCompleteEmailForm';
import { useQuery } from '../helpers/useQuery';
import { SignupCompleteEmailFormErply } from '../component/form/auth/SignupCompleteEmailFormErply';

export const SignupCompleteEmailScreen = () => {
  const { t } = useTranslation();
  const query = useQuery();

  const hash = query.get('hash') as string;
  const hasErplyAccount = query.get('e') === '1';
  return (
    <Container style={{ display: 'flex' }}>
      <Container maxWidth='sm'>
        <Card>
          <CardContent sx={{ p: 3 }}>
            <Typography variant='h5'>{t('auth.completeSignup')}</Typography>
            <Container style={{ display: 'flex' }}>
              {hasErplyAccount ? <SignupCompleteEmailFormErply hash={hash} /> : <SignupCompleteEmailForm hash={hash} />}
            </Container>
          </CardContent>
        </Card>
      </Container>
    </Container>
  );
};
