import { Card, CardContent, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyInviteAccepted } from '../component/form/auth/CompanyInviteAccepted';
import { useQuery } from '../helpers/useQuery';

export const CompanyInviteAcceptedScreen = () => {
  const { t } = useTranslation();
  const query = useQuery();

  return (
    <Container style={{ display: 'flex' }}>
      <Container maxWidth='sm'>
        <Card>
          <CardContent sx={{ p: 3 }}>
            <Typography variant='h5'>{t('auth.companyInvite')}</Typography>
            <CompanyInviteAccepted linkHash={query.get('hash') as string} />
          </CardContent>
        </Card>
      </Container>
    </Container>
  );
};
