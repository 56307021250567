import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SharedMenu } from '../shared/SharedMenu';

type InvoiceItemOptionsMenuProps = {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  handleClose: () => void;
  canReturnItems: boolean;
  setIsReturnsOpen: (isReturnsOpen: boolean) => void;
  setIsProductComplaintOpen: (setIsProductComplaintOpen: boolean) => void;
};

export const InvoiceItemOptionsMenu = (props: InvoiceItemOptionsMenuProps) => {
  const { t } = useTranslation();
  const { anchorEl, isOpen, handleClose, setIsReturnsOpen, setIsProductComplaintOpen, canReturnItems } = props;

  return (
    <SharedMenu anchorEl={anchorEl} isOpen={isOpen} handleClose={handleClose}>
      {canReturnItems ? (
        <MenuItem onClick={() => setIsReturnsOpen(true)}>{t('purchases.createReturn')}</MenuItem>
      ) : null}
      <MenuItem onClick={() => setIsProductComplaintOpen(true)}>{t('purchases.productConcern')}</MenuItem>
    </SharedMenu>
  );
};
