import { Checkbox, Divider, FormControlLabel, FormGroup } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeValidationErrors } from '../../../helpers/mergeErrors';
import { Address } from '../../../slice/api/address';
import { CreateAddressSchema, UpdateAddressSchema } from '../../../validation/schema/address.schema';
import { SharedTextInput } from '../../shared/SharedTextInput';

type AcceptableFieldTypes = CreateAddressSchema | UpdateAddressSchema;
type AddressControlledFormProps = {
  serverError: FetchBaseQueryError | SerializedError | undefined;
  selectedAddress?: Address;
};

export const AddressControlledFormElements = ({ serverError, selectedAddress }: AddressControlledFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    control,
    reset,
    formState: { errors: clientError },
  } = useFormContext<AcceptableFieldTypes>();

  const validationErrors = mergeValidationErrors(serverError, clientError, t);

  useEffect(() => {
    reset(selectedAddress);
  }, [selectedAddress]);

  return (
    <FormGroup>
      <SharedTextInput
        label={t('settings.streetAddress')}
        errorMessage={validationErrors['streetAddress']}
        {...register('streetAddress')}
      />
      <br />

      <SharedTextInput label={t('settings.city')} errorMessage={validationErrors['city']} {...register('city')} />
      <br />

      <SharedTextInput
        label={t('settings.postalCode')}
        errorMessage={validationErrors['postalCode']}
        {...register('postalCode')}
      />
      <br />

      {selectedAddress?.country ? (
        <SharedTextInput label={t('settings.country')} disabled={true} value={selectedAddress.country} />
      ) : null}
      
      <FormControlLabel
        control={
          <Controller
            name={'isPreferredAddress'}
            control={control}
            render={({ field: { value, ref, ...field } }) => <Checkbox {...field} inputRef={ref} checked={!!value} />}
          />
        }
        label={t('settings.preferredAddress')}
      />

      <br />
      <Divider />
    </FormGroup>
  );
};
