import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, EIDEASY_CLIENT_ID, ID_CARD_URL } from '../../config';
import {
  ChangePasswordSchema,
  CompanyInviteAcceptSchema,
  CompanyInviteSchema,
  CompleteEmailSignUpSchema,
  CompleteEmailSignupExistingErplySchema,
  CompleteForgotPasswordSchema,
  CompleteMIDAuthWithTokenSchema,
  CompleteStrongAuthWithTokenSchema,
  CompleteStrongSignupSchema,
  EmailLogInSchema,
  ForgotPasswordSchema,
  RemoveCustomerCompanySchema,
  SetPasswordSchema,
  StartEmailSignUpSchema,
  StartMobileIdSchema,
  StartSmartIdLoginSchema,
  StartStrongSignupSchema,
  SwitchAccountSchema,
} from '../../validation/schema/auth.schema';
import { UpdateCustomerSchema } from '../../validation/schema/customer.schema';
import { getLanguage } from '../../helpers/useSelectedLanguage';

export interface Customer {
  id: number;
  erplyId: number;
  estonianIdVerified: boolean;
  type: 'business' | 'private';
  estonianId?: number;
  email: string;
  companyName?: string;
  phoneNumber: string;
  isNewsletterAccepted: boolean;
}

export type BusinessCustomer = Customer & {
  type: 'business';
  companyName: string;
};

export type PrivateCustomer = Customer & { type: 'private'; firstName?: string; lastName?: string };

export type CompanyCustomer = Pick<PrivateCustomer, 'id' | 'email' | 'firstName' | 'lastName'>;
export type FullCustomer = PrivateCustomer & { hasPassword: boolean } & { companies: BusinessCustomer[] };
export interface LoggedInState {
  loggedInAs: BusinessCustomer | (PrivateCustomer & { hasPassword: boolean });
  fullCustomer: FullCustomer;
}

export type StartIdCardRead = {
  status: 'OK';
  token?: string;
};

export type StrongAuthSignupResponse =
  | {
      action: 'login';
      data: LoggedInState;
    }
  | {
      action: 'signup';
      data: { pendingHash: string };
    };

export type StartStrongAuthResponse = { challenge: string; token: string };

export const authApiSlice = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set('x-selected-language', getLanguage());
      return headers;
    },
  }),
  tagTypes: ['LoggedInUser', 'CompanyCustomers'],
  endpoints: (builder) => ({
    getMyFullUser: builder.query<LoggedInState, void>({
      query: () => '/auth/me',
      providesTags: ['LoggedInUser'],
    }),

    logout: builder.mutation<void, void>({
      query() {
        return {
          url: '/auth/logout',
          method: 'POST',
          data: {},
        };
      },
      invalidatesTags: ['LoggedInUser'],
    }),

    loginEmail: builder.mutation<LoggedInState, EmailLogInSchema>({
      query(body) {
        return {
          url: '/auth/email-login',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['LoggedInUser'],
    }),

    emailSignupStart: builder.mutation<void, StartEmailSignUpSchema>({
      query(body) {
        return {
          url: '/auth/email-signup-start',
          method: 'POST',
          body,
        };
      },
    }),

    completeEmailSignup: builder.mutation<LoggedInState, CompleteEmailSignUpSchema>({
      query(body) {
        return {
          url: '/auth/complete-email-signup',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['LoggedInUser'],
    }),

    completeEmailSignupErply: builder.mutation<LoggedInState, CompleteEmailSignupExistingErplySchema>({
      query(body) {
        return {
          url: '/auth/complete-email-signup-e',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['LoggedInUser'],
    }),

    startIdCard: builder.query<StartIdCardRead, void>({
      query: () => `${ID_CARD_URL}/api/identity/${EIDEASY_CLIENT_ID}/read-card`,
    }),

    startMobileId: builder.mutation<StartStrongAuthResponse, StartMobileIdSchema>({
      query(body) {
        return {
          url: '/auth/start-mobile-id',
          method: 'POST',
          body,
        };
      },
    }),

    startSmartId: builder.mutation<StartStrongAuthResponse, StartSmartIdLoginSchema>({
      query(body) {
        return {
          url: '/auth/start-smart-id',
          method: 'POST',
          body,
        };
      },
    }),

    completeIdCard: builder.mutation<StrongAuthSignupResponse, CompleteStrongAuthWithTokenSchema>({
      query(body) {
        return {
          url: '/auth/complete-id-card',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result) => (result?.action === 'login' ? ['LoggedInUser'] : []),
    }),

    completeMobileId: builder.mutation<StrongAuthSignupResponse, CompleteMIDAuthWithTokenSchema>({
      query(body) {
        return {
          url: '/auth/complete-mobile-id',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result) => (result?.action === 'login' ? ['LoggedInUser'] : []),
    }),

    completeSmartId: builder.mutation<StrongAuthSignupResponse, CompleteStrongAuthWithTokenSchema>({
      query(body) {
        return {
          url: '/auth/complete-smart-id',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result) => (result?.action === 'login' ? ['LoggedInUser'] : []),
    }),

    startStrongAuthSignup: builder.mutation<void, StartStrongSignupSchema>({
      query(body) {
        return {
          url: '/auth/start-strong-signup',
          method: 'POST',
          body,
        };
      },
    }),

    completeStrongAuthSignup: builder.mutation<LoggedInState, CompleteStrongSignupSchema>({
      query(body) {
        return {
          url: '/auth/complete-strong-signup',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['LoggedInUser'],
    }),

    forgotPassword: builder.mutation<void, ForgotPasswordSchema>({
      query(body) {
        return {
          url: '/auth/forgot-password',
          method: 'POST',
          body,
        };
      },
    }),

    completeForgotPassword: builder.mutation<LoggedInState, CompleteForgotPasswordSchema>({
      query(body) {
        return {
          url: '/auth/complete-forgot-password',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['LoggedInUser'],
    }),

    switchAccount: builder.mutation<LoggedInState, SwitchAccountSchema>({
      query(body) {
        return {
          url: '/auth/switch-account',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['LoggedInUser'],
    }),

    companyInvite: builder.mutation<{}, CompanyInviteSchema>({
      query(body) {
        return {
          url: '/auth/company-invite',
          method: 'POST',
          body,
        };
      },
    }),

    companyInviteAccept: builder.mutation<LoggedInState, CompanyInviteAcceptSchema>({
      query(body) {
        return {
          url: '/auth/company-invite-accept',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['LoggedInUser'],
    }),

    updateCustomer: builder.mutation<Customer, UpdateCustomerSchema>({
      query(body) {
        return {
          url: `/customer/update`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['LoggedInUser'],
    }),

    removeCompanyCustomer: builder.mutation<LoggedInState | {}, RemoveCustomerCompanySchema>({
      query(body) {
        return {
          url: `/customer/remove-customer-company`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result) =>
        result && Object.keys(result).length ? ['LoggedInUser', 'CompanyCustomers'] : ['CompanyCustomers'],
    }),

    getCompanyCustomers: builder.query<CompanyCustomer[], void>({
      query: () => `/customer/company-customers`,
      providesTags: ['CompanyCustomers'],
    }),

    deleteCustomer: builder.mutation<number, void>({
      query: () => ({
        url: `/customer/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LoggedInUser'],
    }),

    setPassword: builder.mutation<number, SetPasswordSchema>({
      query: (body) => ({
        url: `/auth/set-password`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LoggedInUser'],
    }),

    changePassword: builder.mutation<number, ChangePasswordSchema>({
      query: (body) => ({
        url: `/auth/change-password`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LoggedInUser'],
    }),
  }),
});

export const {
  useEmailSignupStartMutation,
  useCompleteEmailSignupMutation,
  useCompleteEmailSignupErplyMutation,
  useLoginEmailMutation,
  useGetMyFullUserQuery,
  useStartIdCardQuery,
  useStartMobileIdMutation,
  useStartSmartIdMutation,
  useCompleteIdCardMutation,
  useCompleteMobileIdMutation,
  useCompleteSmartIdMutation,
  useLogoutMutation,
  useStartStrongAuthSignupMutation,
  useCompleteStrongAuthSignupMutation,
  useForgotPasswordMutation,
  useCompleteForgotPasswordMutation,
  useSwitchAccountMutation,
  useCompanyInviteMutation,
  useCompanyInviteAcceptMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useRemoveCompanyCustomerMutation,
  useGetCompanyCustomersQuery,
  useChangePasswordMutation,
  useSetPasswordMutation,
} = authApiSlice;
