import { configureStore } from '@reduxjs/toolkit';
import { addressApiSlice } from '../slice/api/address';
import { authApiSlice } from '../slice/api/auth';
import { invoiceApiSlice } from '../slice/api/invoice';
import { orderApiSlice } from '../slice/api/order';
import { listenerMiddleware } from './listener-middleware';
import { toastSlice } from '../slice/toast';

export default configureStore({
  reducer: {
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [addressApiSlice.reducerPath]: addressApiSlice.reducer,
    [orderApiSlice.reducerPath]: orderApiSlice.reducer,
    [invoiceApiSlice.reducerPath]: invoiceApiSlice.reducer,
    [toastSlice.name]: toastSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      addressApiSlice.middleware,
      authApiSlice.middleware,
      orderApiSlice.middleware,
      invoiceApiSlice.middleware,
      listenerMiddleware.middleware
    ),
});
