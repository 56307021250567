import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Card, CardContent, Grid, Divider, FormGroup, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoggedInUser } from '../../../helpers/useLoggedInUser';
import { useUpdateCustomerMutation } from '../../../slice/api/auth';
import { UpdateBusinessCustomerSchema, updateBusinessCustomerSchema } from '../../../validation/schema/customer.schema';
import { SharedCheckbox } from '../../shared/SharedCheckbox';
import { SharedForm } from '../../shared/SharedForm';
import { SharedTextInput } from '../../shared/SharedTextInput';

export const BusinessUserForm = () => {
  const { t } = useTranslation();
  const user = useLoggedInUser();

  const [updateCustomer, { error: updateCustomerError, isLoading: isUpdateLoading }] = useUpdateCustomerMutation();

  const { register, handleSubmit, watch, formState } = useForm<UpdateBusinessCustomerSchema>({
    resolver: zodResolver(updateBusinessCustomerSchema),
    defaultValues: user,
  });

  if (user.type !== 'business') {
    return null;
  }

  const onSubmit = (data: UpdateBusinessCustomerSchema) => updateCustomer(data);

  return (
    <>
      <Grid item maxWidth='sm' xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant='h5'>{t('settings.myAccount')}</Typography>
            <br />
            <SharedForm onSubmit={handleSubmit(onSubmit)} isLoading={isUpdateLoading} error={updateCustomerError}>
              <FormGroup>
                <SharedTextInput label={t('general.email')} disabled value={user.email} />
                <br />

                <SharedTextInput label={t('general.companyName')} disabled={true} value={user.companyName} />
                <br />

                <SharedTextInput label={t('general.phoneNumber')} disabled={true} value={user.phoneNumber} />
                <br />

                <SharedTextInput disabled={true} label={t('general.regCode')} value={user.estonianId} />
              </FormGroup>
              <br />
              <Divider />
              <br />
              <FormGroup>
                <Typography variant='h6'>{t('settings.newsletter')}</Typography>

                <SharedCheckbox
                  {...register('isNewsletterAccepted')}
                  checked={watch('isNewsletterAccepted')}
                  label={t('settings.acceptNewsletter')}
                />

                <br />
              </FormGroup>

              <FormGroup>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={isUpdateLoading || !formState.isDirty}
                >
                  {t('general.save')}
                </Button>
              </FormGroup>
            </SharedForm>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
