import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Container, FormGroup } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeValidationErrors } from '../../../helpers/mergeErrors';
import { useForgotPasswordMutation } from '../../../slice/api/auth';
import { ForgotPasswordSchema, forgotPasswordSchema } from '../../../validation/schema/auth.schema';
import { LoadingBackdrop } from '../../shared/LoadingBackdrop';
import { SharedForm } from '../../shared/SharedForm';
import { SharedTextInput } from '../../shared/SharedTextInput';

export const ForgotPasswordForm = (props: { defaultEmail?: string }) => {
  const { t } = useTranslation();
  const [forgotPasswordSubmit, { isLoading, error, isSuccess }] = useForgotPasswordMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ForgotPasswordSchema>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: props.defaultEmail ?? '',
    },
  });

  const onSubmit = (data: ForgotPasswordSchema) => forgotPasswordSubmit(data);

  if (isSuccess && !error) {
    return <Container maxWidth='sm'>{t("auth.We've sent you a verification email")}</Container>;
  }

  const validationErrors = mergeValidationErrors(error, errors, t);

  return (
    <>
      <Container maxWidth='sm'>
        <br />
        <SharedForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} error={error}>
          <FormGroup>
            <SharedTextInput
              label={t('general.email')}
              errorMessage={validationErrors['email']}
              {...register('email')}
            />
          </FormGroup>

          <FormGroup sx={{ mt: 3 }}>
            <Button variant='contained' color='primary' type='submit' disabled={!isDirty}>
              {t('general.save')}
            </Button>
          </FormGroup>
          <LoadingBackdrop isLoading={isLoading} />
        </SharedForm>
      </Container>
    </>
  );
};
