import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { Address, useUpdateAddressMutation } from '../../../slice/api/address';
import { UpdateAddressSchema, updateAddressSchema } from '../../../validation/schema/address.schema';
import { SharedFormWithContext } from '../../shared/SharedFormWithContext';
import { AddressControlledFormElements } from './AddressControlledFormElements';
import { Button, FormGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AddressUpdateForm = (props: { selectedAddress: Address; cancelUpdate: () => void }) => {
  const { t } = useTranslation();
  const [updateAddress, { error: serverError, isLoading }] = useUpdateAddressMutation();

  const formMethods = useForm<UpdateAddressSchema>({
    resolver: zodResolver(updateAddressSchema),
    defaultValues: { ...props.selectedAddress },
  });

  return (
    <FormProvider {...formMethods}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        {t('general.edit')}
      </Typography>
      <SharedFormWithContext
        onSubmit={(data: UpdateAddressSchema) => updateAddress({ ...data, id: props.selectedAddress.id })}
        isLoading={isLoading}
        error={serverError}
      >
        <AddressControlledFormElements serverError={serverError} selectedAddress={props.selectedAddress} />
        <br />

        <FormGroup>
          <Button variant='text' onClick={() => props.cancelUpdate()} fullWidth={false}>
            {t('general.cancel')}
          </Button>
          <br />
          <Button variant='contained' color='primary' type='submit' disabled={!formMethods.formState.isDirty}>
            {t('general.save')}
          </Button>
        </FormGroup>
      </SharedFormWithContext>
    </FormProvider>
  );
};
