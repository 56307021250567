import { TableFooter, TablePagination, TableRow } from '@mui/material';

export type PaginationQuery = {
  limit: number;
  page: number;
};

type PaginationProps = {
  colSpan: number;
  count: number;
  rowsPerPage: number;
  page: number;
  setPage: (newPage: number) => void;
};

export const Pagination = (props: PaginationProps) => {
  const { colSpan, count, rowsPerPage, page, setPage } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <TableFooter>
        <TableRow>
          <TablePagination
            colSpan={colSpan}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelRowsPerPage=''
            rowsPerPageOptions={[]}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};
