import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Container, FormGroup, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeValidationErrors } from '../../../../helpers/mergeErrors';
import { useEmailSignupStartMutation } from '../../../../slice/api/auth';
import { StartEmailSignUpSchema, startEmailSignUpSchema } from '../../../../validation/schema/auth.schema';
import { LoadingBackdrop } from '../../../shared/LoadingBackdrop';
import { SharedForm } from '../../../shared/SharedForm';
import { SharedTextInput } from '../../../shared/SharedTextInput';

export const SignupEmailForm = () => {
  const { t } = useTranslation();
  const [signupEmail, { isLoading, error, isSuccess }] = useEmailSignupStartMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<StartEmailSignUpSchema>({
    resolver: zodResolver(startEmailSignUpSchema),
  });

  const onSubmit = (data: StartEmailSignUpSchema) => signupEmail(data);

  if (isSuccess && !error) {
    return <Container maxWidth='sm'>{t("auth.We've sent you a verification email")}</Container>;
  }

  const validationErrors = mergeValidationErrors(error, errors, t);

  return (
    <>
      <Typography variant='h5'>{t('auth.signup')}</Typography>
      <br />
      <SharedForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} error={error}>
        <FormGroup>
          <SharedTextInput label={t('general.email')} errorMessage={validationErrors['email']} {...register('email')} />
          <br />

          <SharedTextInput
            label={t('auth.password')}
            isPassword={true}
            errorMessage={validationErrors['password']}
            {...register('password')}
          />
          <br />
        </FormGroup>

        <FormGroup sx={{ mt: 3 }}>
          <Button variant='contained' color='primary' type='submit'>
            {t('general.save')}
          </Button>
        </FormGroup>
        <LoadingBackdrop isLoading={isLoading} />
      </SharedForm>
    </>
  );
};
