import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCreateNewAddressMutation } from '../../../slice/api/address';
import { CreateAddressSchema, createAddressSchema } from '../../../validation/schema/address.schema';
import { SharedFormWithContext } from '../../shared/SharedFormWithContext';
import { AddressControlledFormElements } from './AddressControlledFormElements';
import { Button, FormGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const initialValues = {
  streetAddress: '',
  city: '',
  postalCode: '',
  country: '',
  isPreferredAddress: false,
};

export const AddressCreateForm = () => {
  const { t } = useTranslation();
  const [createAddress, { error: serverError, isLoading, isSuccess }] = useCreateNewAddressMutation();

  const formMethods = useForm<CreateAddressSchema>({
    resolver: zodResolver(createAddressSchema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (isSuccess) {
      formMethods.reset(initialValues);
    }
  }, [isSuccess]);

  return (
    <FormProvider {...formMethods}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        {t('general.add')}
      </Typography>
      <SharedFormWithContext
        onSubmit={(data: CreateAddressSchema) => createAddress(data)}
        isLoading={isLoading}
        error={serverError}
      >
        <AddressControlledFormElements serverError={serverError} />

        <FormGroup>
          <Button variant='contained' color='primary' type='submit' disabled={!formMethods.formState.isDirty}>
            {t('general.save')}
          </Button>
        </FormGroup>
      </SharedFormWithContext>
    </FormProvider>
  );
};
