import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Container, FormGroup, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { mergeValidationErrors } from '../../../../helpers/mergeErrors';
import { useCompleteMobileIdMutation, useStartMobileIdMutation } from '../../../../slice/api/auth';
import { startMobileIdSchema, StartMobileIdSchema } from '../../../../validation/schema/auth.schema';
import { LoadingBackdrop } from '../../../shared/LoadingBackdrop';
import { SharedForm } from '../../../shared/SharedForm';
import { SharedTextInput } from '../../../shared/SharedTextInput';
import { isProd } from '../../../../config';

const defaultMobileAuthFormValues = isProd
  ? {
      phoneNumber: '',
      idCode: '',
    }
  : {
      phoneNumber: '+37200000766', // OK, delay 7 sec https://github.com/SK-EID/MID/wiki/Test-number-for-automated-testing-in-DEMO
      idCode: '60001019906',
    };

const MobileIdWaitingForResponse = (props: {
  challenge: string;
  token: string;
  phoneNumber: string;
  reset: () => void;
}) => {
  const { t } = useTranslation();
  const [completeMobileIdAuth, { data, isError, error }] = useCompleteMobileIdMutation();

  useEffect(() => {
    const { abort } = completeMobileIdAuth({ token: props.token, phoneNumber: props.phoneNumber });
    return () => abort();
  }, [props.token, completeMobileIdAuth]);

  const restartMobileId = () => {
    props.reset();
  };

  if (isError && error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessage = error.data?.message ? error.data.message : t('auth.There was a problem authenticating.');
    return (
      <Container maxWidth='sm'>
        {errorMessage}
        <Button onClick={() => restartMobileId()}>{t('general.tryAgain')}</Button>
      </Container>
    );
  }

  if (data?.action === 'signup' && data?.data.pendingHash) {
    return <Navigate to={`/strong-signup?hash=${data.data.pendingHash}`} />;
  }

  return (
    <Container maxWidth='sm'>
      {t('auth.Waiting for response. Check code {{challenge}}', { challenge: props.challenge })}
    </Container>
  );
};

export const MobileIdAuth = () => {
  const { t } = useTranslation();
  const [startMobileIdAuth, { isLoading, error, data, reset, isSuccess }] = useStartMobileIdMutation();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<StartMobileIdSchema>({
    resolver: zodResolver(startMobileIdSchema),
    defaultValues: defaultMobileAuthFormValues,
  });
  const validationErrors = mergeValidationErrors(error, errors, t);

  if (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessage = error.data?.message ? error.data.message : t('auth.There was a problem authenticating.');
    return (
      <Container maxWidth='sm'>
        {errorMessage}
        <Button onClick={() => reset()}>{t('general.tryAgain')}</Button>
      </Container>
    );
  }

  if (isSuccess && !error && data) {
    const { phoneNumber } = getValues();
    return (
      <MobileIdWaitingForResponse
        challenge={data.challenge}
        token={data.token}
        reset={reset}
        phoneNumber={phoneNumber}
      />
    );
  }

  return (
    <>
      <Typography variant='h5'>{t('auth.continue with mobile ID')}</Typography>
      <br />
      <SharedForm onSubmit={handleSubmit((body) => startMobileIdAuth(body))} isLoading={isLoading}>
        <FormGroup>
          <SharedTextInput
            label={t('general.phoneNumber')}
            errorMessage={validationErrors['phoneNumber']}
            {...register('phoneNumber')}
          />
          <br />
          <SharedTextInput
            label={t('general.personalId')}
            errorMessage={validationErrors['idCode']}
            {...register('idCode')}
          />
        </FormGroup>

        <FormGroup sx={{ mt: 3 }}>
          <Button variant='contained' color='primary' type='submit'>
            {t('general.begin')}
          </Button>
        </FormGroup>
        <LoadingBackdrop isLoading={isLoading} />
      </SharedForm>
    </>
  );
};
