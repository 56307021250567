import { z } from 'zod';
import { phoneNumberValidator } from '../sharedValidators';

export const updatePrivateCustomerSchema = z.object({
  firstName: z.string().min(2, { message: 'validationError.FIRST_NAME' }),
  lastName: z.string().min(2, { message: 'validationError.LAST_NAME' }),
  phoneNumber: phoneNumberValidator,
  isNewsletterAccepted: z.boolean(),
});
export type UpdatePrivateCustomerSchema = z.infer<typeof updatePrivateCustomerSchema>;

export const updatePrivateVerifiedCustomerSchema = z.object({
  phoneNumber: phoneNumberValidator,
  isNewsletterAccepted: z.boolean(),
});
export type UpdatePrivateVerifiedCustomerSchema = z.infer<typeof updatePrivateVerifiedCustomerSchema>;

export const updateBusinessCustomerSchema = z.object({
  isNewsletterAccepted: z.boolean(),
});
export type UpdateBusinessCustomerSchema = z.infer<typeof updateBusinessCustomerSchema>;

export type UpdateCustomerSchema =
  | UpdatePrivateCustomerSchema
  | UpdatePrivateVerifiedCustomerSchema
  | UpdateBusinessCustomerSchema;
