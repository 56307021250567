import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetInvoicesQuery } from '../../slice/api/invoice';
import { TableHeadCell } from '../ordersTable/OrdersTable';
import { HistoryTableRow } from './HistoryTableRow';
import { useState } from 'react';
import { LoadingBackdrop } from '../shared/LoadingBackdrop';
import { Pagination } from '../shared/Pagination';

export const HistoryTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  const { data, isLoading } = useGetInvoicesQuery({ limit: rowsPerPage, page });

  const invoices = data?.invoices;

  const headCells: TableHeadCell[] = [
    {
      id: 'expandDetails',
      align: 'inherit',
      label: '',
    },
    {
      id: 'invoiceNumber',
      align: 'inherit',
      label: t('purchases.invoiceNumber'),
    },
    {
      id: 'quantity',
      align: 'inherit',
      label: t('purchases.quantity'),
    },
    {
      id: 'total',
      align: 'inherit',
      label: t('purchases.total'),
    },

    {
      id: 'date',
      align: 'inherit',
      label: t('purchases.date'),
    },
    {
      id: 'invoiceNote',
      align: 'inherit',
      label: t('purchases.note'),
    },
    {
      id: 'optionsMenu',
      align: 'right',
      label: '',
    },
  ];

  return (
    <>
      <Card sx={{ width: '100%', mt: { xs: 1, sm: 2, md: 4, lg: 6 } }}>
        <CardContent>
          <Typography variant='h5' sx={{ py: { xs: 1, md: 1, lg: 2 }, px: { xs: 0, md: 0, lg: 2 } }}>
            {t('purchases.history')}
          </Typography>
          <TableContainer className='table-holder-responsive'>
            <Table aria-label='history table'>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align}>
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices?.map((invoice) => (
                  <HistoryTableRow key={invoice.id} invoice={invoice} />
                ))}
              </TableBody>
              <Pagination
                setPage={setPage}
                page={page}
                count={data?.count ?? 0}
                rowsPerPage={rowsPerPage}
                colSpan={headCells.length}
              />
            </Table>
          </TableContainer>
          <LoadingBackdrop isLoading={isLoading} />
        </CardContent>
      </Card>
    </>
  );
};
