import { Container } from '@mui/material';
import { CompanyInviteForm } from '../component/form/company/CompanyInviteForm';

export const CompanyAddInvitationScreen = () => {
  return (
    <Container style={{ display: 'flex' }}>
      <CompanyInviteForm />
    </Container>
  );
};
