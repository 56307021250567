import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEN from './en.json';
import translationsET from './et.json';
import translationsRU from './ru.json';

const resources = {
  en: {
    translation: translationsEN,
  },
  et: {
    translation: translationsET,
  },
  ru: {
    translation: translationsRU,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'et',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
