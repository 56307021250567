import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoItemsToShow = ({ purchaseId, colSpan }: { purchaseId: number; colSpan: number }) => {
  const { t } = useTranslation();
  return (
    <TableRow key={'no-items-order' + purchaseId}>
      <TableCell component='th' scope='row' colSpan={colSpan} align='center'>
        {t('purchases.noItems')}
      </TableCell>
    </TableRow>
  );
};
