import { Button, MenuItem, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../config';
import { useSelectedLanguage } from '../../helpers/useSelectedLanguage';
import { useSendToEmailQuery } from '../../slice/api/invoice';
import { LoadingBackdrop } from '../shared/LoadingBackdrop';
import { SharedMenu } from '../shared/SharedMenu';

type InvoiceOptionsMenuProps = {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  erplyId: number;
  handleClose: () => void;
};

export const InvoiceOptionsMenu = (props: InvoiceOptionsMenuProps) => {
  const { t } = useTranslation();
  const [sendInvoiceToEmail, setSendInvoiceToEmail] = useState(false);
  const { anchorEl, isOpen, handleClose, erplyId } = props;
  const language = useSelectedLanguage();
  const { isSuccess, isError, isLoading, refetch } = useSendToEmailQuery(
    { erplyId, language },
    {
      skip: !sendInvoiceToEmail,
    }
  );

  const tryAgainAction = (
    <>
      <Button color='secondary' size='small' onClick={() => refetch()}>
        {t('general.tryAgain')}
      </Button>
    </>
  );

  return (
    <>
      <SharedMenu anchorEl={anchorEl} isOpen={isOpen} handleClose={handleClose}>
        <MenuItem
          href={`${API_URL}/invoice/download?erplyId=${erplyId}&language=${language}`}
          target='_blank'
          component='a'
        >
          {t('purchases.downloadInvoice')}
        </MenuItem>
        <MenuItem onClick={() => setSendInvoiceToEmail(true)}>{t('purchases.sendInvoiceToEmail')}</MenuItem>
      </SharedMenu>
      <LoadingBackdrop isLoading={isLoading} />
      <Snackbar
        open={isSuccess || isError}
        autoHideDuration={6000}
        onClose={() => setSendInvoiceToEmail(false)}
        message={isSuccess ? t('purchases.invoice sent to email') : t('purchases.error sending invoice to email')}
        action={isError ? tryAgainAction : undefined}
      />
    </>
  );
};
