import { Box, Container, CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Header } from './component/header/Header';
import { Navigator } from './component/navigator/Navigator';
import { SelectLanguage } from './component/shared/SelectLanguage';
import { useLoggedInFullUser } from './helpers/useLoggedInUser';
import { CompanyScreen } from './screen/Company';
import { CompanyAddInvitationScreen } from './screen/CompanyAddInvitation';
import { CompanyInviteAcceptedScreen } from './screen/CompanyInviteAccepted';
import { CompleteForgotPasswordScreen } from './screen/CompleteForgotPassword';
import { ForgotPasswordScreen } from './screen/ForgotPassword';
import { LoginScreen } from './screen/Login';
import { PurchasesScreen } from './screen/Purchases';
import { SettingsScreen } from './screen/Settings';
import { SignupScreen } from './screen/Signup';
import { SignupCompleteEmailScreen } from './screen/SignupCompleteEmail';
import { SignupCompleteStrongAuthScreen } from './screen/SignupCompleteStrongAuth';
import { SignupStartStrongAuthScreen } from './screen/SignupStartStrongAuth';

const NON_AUTH_PATHS_TO_ALLOW_WHILE_LOGGED_IN = ['/company-invite-accept'];

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Navigate to='/login' />} />
        <Route path='login' element={<LoginScreen />} />
        <Route path='signup' element={<SignupScreen />} />
        <Route path='strong-signup' element={<SignupStartStrongAuthScreen />} />
        <Route path='email-signup-complete' element={<SignupCompleteEmailScreen />} />
        <Route path='strong-signup-complete' element={<SignupCompleteStrongAuthScreen />} />
        <Route path='forgot-password' element={<ForgotPasswordScreen />} />
        <Route path='complete-forgot-password' element={<CompleteForgotPasswordScreen />} />
        <Route path='company-invite-accept' element={<CompanyInviteAcceptedScreen />} />
      </Route>

      <Route path='/dashboard' element={<LoggedInLayout />}>
        <Route index element={<PurchasesScreen />} />
        <Route path='settings' element={<SettingsScreen />} />
        <Route path='company-add-invitation' element={<CompanyAddInvitationScreen />} />
        <Route path='company' element={<CompanyScreen />} />
        <Route path='*' element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    white: string;
    darkgray: string;
    lightgray: string;
  }
}

const generalTheme = createTheme({
  palette: {
    primary: {
      main: '#2FA828',
      dark: '#549444',
    },
    secondary: {
      main: '#E60003',
      dark: '#C70C0E',
    },
    common: {
      white: '#fff',
      darkgray: '#2D2D2A',
      lightgray: '#F2F2F2',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

const theme = {
  ...generalTheme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: generalTheme.palette.common.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: generalTheme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: generalTheme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: generalTheme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

function Layout() {
  const user = useLoggedInFullUser();
  const location = useLocation();
  const isAllowedEvenIfLoggedIn = NON_AUTH_PATHS_TO_ALLOW_WHILE_LOGGED_IN.includes(location.pathname);

  if (user && !isAllowedEvenIfLoggedIn) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
          backgroundColor: generalTheme.palette.common.lightgray,
        }}
      >
        <CssBaseline />
        <Box sx={{ m: 'auto', width: '100%', maxWidth: 500 }}>
          <Container sx={{ display: { xs: 'flex' }, justifyContent: 'flex-end', px: 3 }}>
            <Box sx={{ p: 3 }}>
              <SelectLanguage />
            </Box>
          </Container>
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function LoggedInLayout() {
  const user = useLoggedInFullUser();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmUp = useMediaQuery(generalTheme.breakpoints.up('sm'));

  if (!user) {
    return <Navigate to='/login' />;
  }

  const drawerWidth = 256;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
          {isSmUp ? null : (
            <Navigator
              drawerProps={{
                PaperProps: { style: { width: drawerWidth } },
                variant: 'temporary',
                open: mobileOpen,
                onClose: handleDrawerToggle,
              }}
            />
          )}
          <Navigator
            drawerProps={{
              PaperProps: { style: { width: drawerWidth } },
              sx: { display: { sm: 'block', xs: 'none' } },
            }}
          />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header onDrawerToggle={handleDrawerToggle} />
          <Box
            component='main'
            sx={{
              flex: 1,
              py: { xs: 1, sm: 2, md: 4, lg: 6 },
              px: { xs: 1, sm: 2, md: 4, lg: 6 },
              bgcolor: '#F2F2F2',
            }}
          >
            {/* An <Outlet> renders whatever child route is currently active,
                so you can think about this <Outlet> as a placeholder for
                the child routes we defined above. */}
            <Outlet />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to='/'>Go to the home page</Link>
      </p>
    </div>
  );
}
