import { Container } from '@mui/material';
import { CompanyCustomerTable } from '../component/companyCustomerTable/CompanyCustomerTable';

export const CompanyScreen = () => {
  return (
    <Container style={{ display: 'flex' }}>
      <CompanyCustomerTable />
    </Container>
  );
};
