import dayjs from 'dayjs';
import { getLocalStorageItem, removeLocalStorageItem, useLocalStorage } from './useLocalStorage';

const VALUE_KEY = 'redirect_uri_return';
const EXPIRES_KEY = 'redirect_uri_return_expires';

export const getRedirectURIReturn = () => {
  const expiresString = getLocalStorageItem<string>(EXPIRES_KEY);
  const expiresDate = expiresString ? dayjs.unix(Number(expiresString)) : null;
  if (expiresDate && dayjs().isAfter(expiresDate)) {
    removeRedirectURIReturn();
    return '';
  }
  return getLocalStorageItem<string>(VALUE_KEY) ?? '';
};

export const removeRedirectURIReturn = () => {
  removeLocalStorageItem(EXPIRES_KEY);
  removeLocalStorageItem(VALUE_KEY);
};

export const useSetRedirectURIReturn = () => {
  const setRedirectURI = useLocalStorage<string>(VALUE_KEY, '')[1];
  const setRedirectExpiry = useLocalStorage<string>(EXPIRES_KEY, '')[1];
  const expireTime = dayjs().add(1, 'hour').unix().toString();

  return (uri: string) => {
    setRedirectExpiry(expireTime);
    setRedirectURI(uri);
  };
};
