import { Button, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyCustomer, useRemoveCompanyCustomerMutation } from '../../slice/api/auth';
import { LoadingBackdrop } from '../shared/LoadingBackdrop';
import { useLoggedInFullUser } from '../../helpers/useLoggedInUser';
import { useEffect } from 'react';

interface CustomerRowProps {
  customer: CompanyCustomer;
  idx: number;
}
export const CompanyCustomerTableRow = (props: CustomerRowProps) => {
  const { t } = useTranslation();
  const { customer, idx } = props;
  const fullUser = useLoggedInFullUser();
  const [deleteCustomer, { isLoading, isSuccess }] = useRemoveCompanyCustomerMutation();

  const isMe = customer.id === fullUser?.fullCustomer?.id;

  useEffect(() => {
    if (isSuccess && isMe) {
      window.location.href = '/dashboard';
    }
  }, [isSuccess, isMe]);

  return (
    <>
      <TableRow sx={{}}>
        <TableCell component='th' scope='row'>
          {idx + 1}
        </TableCell>
        <TableCell align='inherit'>
          <Typography variant='overline' gutterBottom display={{ xs: 'block', lg: 'none' }}>
            {t('company.customerName')}
          </Typography>
          <Typography variant='body1'>
            {customer.firstName} {customer.lastName}
          </Typography>
        </TableCell>
        <TableCell align='inherit'>
          <Typography variant='overline' gutterBottom display={{ xs: 'block', lg: 'none' }}>
            {t('company.customerEmail')}
          </Typography>
          <Typography variant='body1'>{customer.email}</Typography>
        </TableCell>
        <TableCell align='inherit'>
          <Button
            variant='contained'
            color='error'
            size='small'
            onClick={() => deleteCustomer({ customerId: customer.id })}
          >
            X
          </Button>
          <LoadingBackdrop isLoading={isLoading} />
        </TableCell>
      </TableRow>
    </>
  );
};
