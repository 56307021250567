import { forwardRef } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type SharedTextInputProps = TextFieldProps & {
  errorMessage?: string;
  isPassword?: boolean;
};

export const SharedTextInput = forwardRef((props: SharedTextInputProps, ref) => {
  const { errorMessage, isPassword, ...inputProps } = props;

  return (
    <TextField
      sx={{ backgroundColor: '#fff' }}
      inputRef={ref}
      type={isPassword ? 'password' : 'text'}
      error={!!errorMessage}
      helperText={errorMessage}
      {...inputProps}
      size='small'
    />
  );
});
