import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const LoadingBackdrop = (props: { isLoading: boolean }) => {
  return (
    <Backdrop open={props.isLoading}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
