import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormGroup } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeValidationErrors } from '../../../helpers/mergeErrors';
import { useStartStrongAuthSignupMutation } from '../../../slice/api/auth';
import { StartStrongSignupSchema, startStrongSignupSchema } from '../../../validation/schema/auth.schema';
import { LoadingBackdrop } from '../../shared/LoadingBackdrop';
import { SharedForm } from '../../shared/SharedForm';
import { SharedTextInput } from '../../shared/SharedTextInput';

export const StartStrongAuthSignupForm = ({ pendingHash }: { pendingHash: string }) => {
  const { t } = useTranslation();
  const [startStrongAuthSignup, { isLoading, error: signupErrors, isSuccess }] = useStartStrongAuthSignupMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<StartStrongSignupSchema>({
    resolver: zodResolver(startStrongSignupSchema),
    defaultValues: {
      pendingStrongAuthHash: pendingHash,
    },
  });

  const onSubmit = (data: StartStrongSignupSchema) => startStrongAuthSignup(data);

  if (isSuccess) {
    return <>{t("auth.We've sent you a verification email")}</>;
  }

  const validationErrors = mergeValidationErrors(signupErrors, errors, t);

  return (
    <SharedForm onSubmit={handleSubmit(onSubmit)} error={signupErrors} isLoading={isLoading}>
      <FormGroup>
        <br />
        <SharedTextInput label={t('general.email')} errorMessage={validationErrors['email']} {...register('email')} />
        <br />
      </FormGroup>

      <FormGroup sx={{ mt: 3 }}>
        <Button variant='contained' color='primary' type='submit' disabled={!isDirty}>
          {t('general.save')}
        </Button>
      </FormGroup>
      <LoadingBackdrop isLoading={isLoading} />
    </SharedForm>
  );
};
