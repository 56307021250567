import { Container } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompleteEmailSignupErplyMutation } from '../../../slice/api/auth';
import { LoadingBackdrop } from '../../shared/LoadingBackdrop';
import { Navigate } from 'react-router-dom';
import { ClientError } from '../ClientError';

type SignupCompleteEmailFormProps = {
  hash: string;
};

export const SignupCompleteEmailFormErply = (props: SignupCompleteEmailFormProps) => {
  const { t } = useTranslation();
  const [completeSignup, { data, isSuccess, error, isLoading }] = useCompleteEmailSignupErplyMutation();

  useEffect(() => {
    const { abort } = completeSignup({ linkHash: props.hash });
    return () => abort();
  }, [props.hash, completeSignup]);

  if (isSuccess && data?.loggedInAs?.id) {
    return <Navigate to={`/dashboard`} />;
  }

  if (error) {
    return (
      <Container maxWidth='sm'>
        <ClientError error={error} />
      </Container>
    );
  }

  return (
    <Container maxWidth='sm'>
      {t('auth.Waiting for response')}
      <LoadingBackdrop isLoading={isLoading} />
    </Container>
  );
};
