import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Container, FormGroup } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeValidationErrors } from '../../../helpers/mergeErrors';
import { useCompleteForgotPasswordMutation } from '../../../slice/api/auth';
import { CompleteForgotPasswordSchema, completeForgotPasswordSchema } from '../../../validation/schema/auth.schema';
import { LoadingBackdrop } from '../../shared/LoadingBackdrop';
import { SharedForm } from '../../shared/SharedForm';
import { SharedTextInput } from '../../shared/SharedTextInput';

type CompleteForgotPasswordFormProps = {
  hash: string;
};
export const CompleteForgotPasswordForm = ({ hash }: CompleteForgotPasswordFormProps) => {
  const { t } = useTranslation();
  const [forgotPasswordCompleteSubmit, { isLoading, error, isSuccess }] = useCompleteForgotPasswordMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<CompleteForgotPasswordSchema>({
    resolver: zodResolver(completeForgotPasswordSchema),
    defaultValues: {
      linkHash: hash,
      password: '',
    },
  });

  const onSubmit = (data: CompleteForgotPasswordSchema) => forgotPasswordCompleteSubmit(data);

  if (isSuccess && !error) {
    return <Container maxWidth='sm'>{t("auth.We've sent you a verification email")}</Container>;
  }

  const validationErrors = mergeValidationErrors(error, errors, t);

  return (
    <>
      <Container maxWidth='sm'>
        <br />
        <SharedForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} error={error}>
          <FormGroup>
            <SharedTextInput
              label={t('auth.password')}
              isPassword={true}
              errorMessage={validationErrors['password']}
              {...register('password')}
            />
          </FormGroup>

          <FormGroup sx={{ mt: 3 }}>
            <Button variant='contained' color='primary' type='submit' disabled={!isDirty}>
              {t('general.save')}
            </Button>
          </FormGroup>
          <LoadingBackdrop isLoading={isLoading} />
        </SharedForm>
      </Container>
    </>
  );
};
