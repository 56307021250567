import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { RETURN_POLICY_DAYS } from '../../config';
import { filterItemRows } from '../../helpers/filterItemRows';
import { Invoice } from '../../slice/api/invoice';
import { TableHeadCell } from '../ordersTable/OrdersTable';
import { NoItemsToShow } from '../shared/NoItemsToShow';
import { InvoiceDetailsRow } from './InvoiceDetailsRow';

type InvoiceDetailsProps = {
  isOpen: boolean;
  invoice: Invoice;
};

export const InvoiceDetails = (props: InvoiceDetailsProps) => {
  const { t } = useTranslation();
  const { isOpen, invoice } = props;
  const { items = [], id } = invoice;
  const invoiceId = id;
  const { transportationRowData, itemRowsData } = filterItemRows(items);

  const today = dayjs().format('YYYY-MM-DD');
  const purchaseDate = dayjs(invoice.date).format('YYYY-MM-DD');
  const diffDays = dayjs(today).diff(purchaseDate, 'day');
  const canReturnItems = diffDays <= RETURN_POLICY_DAYS;

  const headCells: TableHeadCell[] = [
    {
      id: 'itemCode',
      align: 'inherit',
      label: t('purchases.itemCode'),
    },
    {
      id: 'productOrService',
      align: 'inherit',
      label: t('purchases.productOrService'),
    },
    {
      id: 'quantity',
      align: 'inherit',
      label: t('purchases.quantity'),
    },
    {
      id: 'price',
      align: 'inherit',
      label: t('purchases.price'),
    },
    {
      id: 'total',
      align: 'inherit',
      label: t('purchases.total'),
    },
    {
      id: 'options',
      align: 'inherit',
      label: '',
    },
  ];

  return (
    <>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={isOpen} timeout='auto' unmountOnExit>
            <Box sx={{ backgroundColor: 'rgba(0,0,0,0.03)', py: 2, px: 2 }}>
              <Table size='small' aria-label='orders'>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.align}>
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemRowsData?.length ? (
                    itemRowsData.map((item) => (
                      <InvoiceDetailsRow
                        key={`${item.erplyId}-${item.id}`}
                        item={item}
                        canReturnItems={canReturnItems}
                        displayMenu={item.quantity > 0}
                      />
                    ))
                  ) : (
                    <NoItemsToShow purchaseId={invoiceId} colSpan={6} />
                  )}
                  {transportationRowData?.length
                    ? transportationRowData?.map((item) => (
                        <InvoiceDetailsRow key={`${item.erplyId}-${item.id}`} item={item} />
                      ))
                    : null}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
