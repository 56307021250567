import { HistoryTable } from '../component/historyTable/HistoryTable';
import { OrdersTable } from '../component/ordersTable/OrdersTable';
import { LastSynced } from '../component/shared/LastSynced';

export const PurchasesScreen = () => {
  return (
    <>
      <OrdersTable />
      <HistoryTable />
      <LastSynced />
    </>
  );
};
