import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { filterItemRows } from '../../helpers/filterItemRows';
import { LocalNote, OrderItem } from '../../slice/api/order';
import { NoItemsToShow } from '../shared/NoItemsToShow';
import { OrderDetailsRow } from './OrderDetailsRow';
import { LocalNoteAdd } from './LocalNoteAdd';

type OrderDetailsProps = {
  items: OrderItem[];
  localNotes?: LocalNote[];
  isOpen: boolean;
  orderId: number;
  orderNumber: string;
  orderErplyId: number;
};

export const OrderDetails = (props: OrderDetailsProps) => {
  const { t } = useTranslation();
  const { items, localNotes, isOpen, orderId, orderNumber, orderErplyId } = props;
  const { transportationRowData, itemRowsData } = filterItemRows(items);

  return (
    <>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={isOpen} timeout='auto' unmountOnExit>
            <Box sx={{ backgroundColor: 'rgba(0,0,0,0.03)', py: 2, px: 2 }}>
              <Table size='small' aria-label='orders'>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('purchases.itemCode')}</TableCell>
                    <TableCell>{t('purchases.productOrService')}</TableCell>
                    <TableCell>{t('purchases.quantity')}</TableCell>
                    <TableCell>{t('purchases.price')}</TableCell>
                    <TableCell>{t('purchases.total')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.length ? (
                    itemRowsData.map((item) => <OrderDetailsRow key={`${item.erplyId}-${item.id}`} item={item} />)
                  ) : (
                    <NoItemsToShow purchaseId={orderId} colSpan={6} />
                  )}
                  {transportationRowData?.length
                    ? transportationRowData.map((item) => (
                        <OrderDetailsRow key={`${item.erplyId}-${item.id}`} item={item} />
                      ))
                    : null}
                </TableBody>
              </Table>
              <LocalNoteAdd localNotes={localNotes} orderNumber={orderNumber} orderErplyId={orderErplyId} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
