import { z } from 'zod';
import { phoneNumberValidator, emailValidation, strongPasswordValidation } from '../sharedValidators';

export const emailLogInSchema = z.object({
  email: emailValidation,
  password: strongPasswordValidation,
});
export type EmailLogInSchema = z.infer<typeof emailLogInSchema>;

export const forgotPasswordSchema = z.object({
  email: emailValidation,
});
export type ForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>;

export const completeForgotPasswordSchema = z.object({
  linkHash: z.string(),
  password: strongPasswordValidation,
});
export type CompleteForgotPasswordSchema = z.infer<typeof completeForgotPasswordSchema>;

export const startSmartIdLoginSchema = z.object({
  idCode: z.string().min(11, { message: 'validationError.ESTONIAN_ID' }),
});
export type StartSmartIdLoginSchema = z.infer<typeof startSmartIdLoginSchema>;

export const startEmailSignUpSchema = z.object({
  email: emailValidation,
  password: strongPasswordValidation,
});
export type StartEmailSignUpSchema = z.infer<typeof startEmailSignUpSchema>;

export const startMobileIdSchema = z.object({
  idCode: z.string().max(11).min(11, { message: 'validationError.ESTONIAN_ID' }),
  phoneNumber: phoneNumberValidator,
});
export type StartMobileIdSchema = z.infer<typeof startMobileIdSchema>;

export const completeEmailSignupExistingErplySchema = z.object({
  linkHash: z.string(),
});
export type CompleteEmailSignupExistingErplySchema = z.infer<typeof completeEmailSignupExistingErplySchema>;

export const completeEmailSignUpSchema = z.object({
  linkHash: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});
export type CompleteEmailSignUpSchema = z.infer<typeof completeEmailSignUpSchema>;

export const startStrongSignupSchema = z.object({
  pendingStrongAuthHash: z.string(),
  email: emailValidation,
});
export type StartStrongSignupSchema = z.infer<typeof startStrongSignupSchema>;

export const completeStrongSignupSchema = z.object({
  linkHash: z.string(),
});
export type CompleteStrongSignupSchema = z.infer<typeof completeStrongSignupSchema>;

export const completeStrongAuthWithTokenSchema = z.object({
  token: z.string(),
});
export type CompleteStrongAuthWithTokenSchema = z.infer<typeof completeStrongAuthWithTokenSchema>;

export const completeMIDAuthWithTokenSchema = z.object({
  token: z.string(),
  phoneNumber: phoneNumberValidator,
});
export type CompleteMIDAuthWithTokenSchema = z.infer<typeof completeMIDAuthWithTokenSchema>;

export const companyInviteSchema = z.object({
  regCode: z.string(),
});
export type CompanyInviteSchema = z.infer<typeof companyInviteSchema>;

export const companyInviteAcceptSchema = z.object({
  linkHash: z.string(),
});
export type CompanyInviteAcceptSchema = z.infer<typeof companyInviteAcceptSchema>;

export const switchAccountSchema = z.object({
  customerId: z.number(),
});
export type SwitchAccountSchema = z.infer<typeof switchAccountSchema>;

export const removeCustomerCompanySchema = z.object({
  customerId: z.number(),
});
export type RemoveCustomerCompanySchema = z.infer<typeof removeCustomerCompanySchema>;

export const changePasswordSchema = z.object({
  oldPassword: strongPasswordValidation,
  newPassword: strongPasswordValidation,
  newPasswordConfirmation: strongPasswordValidation,
});
export type ChangePasswordSchema = z.infer<typeof changePasswordSchema>;

export const setPasswordSchema = z.object({
  newPassword: strongPasswordValidation,
  newPasswordConfirmation: strongPasswordValidation,
});
export type SetPasswordSchema = z.infer<typeof setPasswordSchema>;
