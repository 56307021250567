export const isProd = process.env.NODE_ENV === 'production';

export const API_URL = isProd ? 'https://minu.onoff.ee/api' : 'http://localhost:3001';
export const EIDEASY_CLIENT_ID = isProd ? 'puZE8bi14YAcLWOvZHFyIomoPtrFmIco' : 'wj5xou2Xlq5cN3lBFz83VWjtDbMuPXnl';
export const ID_CARD_URL = 'https://ee.eideasy.com';
export const RETURN_LOCATIONS = [
  'T1 ONOFF',
  'Sikupilli ONOFF',
  'Mustika ONOFF',
  'Tähesaju ONOFF',
  'Tartu ONOFF',
  'Annelinna ONOFF',
  'Narva ONOFF',
  'Saaremaa ONOFF',
  'Pärnu ONOFF',
];
export const RETURN_POLICY_DAYS = 15;
