import ErrorIcon from '@mui/icons-material/Error';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ClientErrors = {
  TOO_MANY_ERPLY_ACCOUNTS: 'TOO_MANY_ERPLY_ACCOUNTS',
  INVALID_SIGNUP_LINK: 'INVALID_SIGNUP_LINK',
} as const;

export type ClientErrorType = (typeof ClientErrors)[keyof typeof ClientErrors];

type ClientErrorProps = {
  error?: any;
};

export const ClientError = (props: ClientErrorProps) => {
  const { t } = useTranslation();
  const { error } = props;

  if (!error) return null;

  const clientErrorData = error.data?.clientError;

  if (!clientErrorData) return null;

  return (
    <>
      <Stack direction='row' alignItems='center' gap={1}>
        <ErrorIcon color='error' />
        <Typography color='error' variant='subtitle1'>
          {t(`clientError.${clientErrorData}`)}
        </Typography>
      </Stack>
      <br />
    </>
  );
};
