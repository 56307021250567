import { InvoiceItem } from '../slice/api/invoice';
import { OrderItem } from '../slice/api/order';

type SalesDocumentItem = OrderItem | InvoiceItem;

const isTransportationItem = (productCode: string) => {
  const endsWithQ = productCode.endsWith('Q');
  const startsWithCode = productCode.startsWith('2022W');
  return endsWithQ || startsWithCode;
};

// Transportation item codes seem to end with "Q" or start with "2022W"
export const filterItemRows = <T extends SalesDocumentItem>(items: T[]) => {
  const transportationRowData = items.filter((item) => isTransportationItem(item.productCode));
  const itemRowsData = items.filter((item) => !isTransportationItem(item.productCode));

  return { transportationRowData, itemRowsData };
};
