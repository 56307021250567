import { ClientError } from '../form/ClientError';
import { LoadingBackdrop } from './LoadingBackdrop';

type SharedFormProps = {
  children: React.ReactNode;
  error?: any;
  isLoading?: boolean;
  onSubmit: (data?: any) => void;
};

export const SharedForm = (props: SharedFormProps) => {
  return (
    <form onSubmit={props.onSubmit}>
      <ClientError error={props.error} />
      {props.children}
      <LoadingBackdrop isLoading={!!props.isLoading} />
    </form>
  );
};
