import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressCreateForm } from './AddressCreateForm';
import { AddressUpdateForm } from './AddressUpdateForm';
import { CurrentAddresses } from './CurrentAddresses';
import { Address } from '../../../slice/api/address';

export const AddressSettingsForm = () => {
  const { t } = useTranslation();

  const [selectedAddress, setSelectedAddress] = useState<Address>();

  return (
    <>
      <Grid item maxWidth='sm' xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant='h5'>{t('settings.address')}</Typography>
            <br />
            <CurrentAddresses selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} />
            <br />
            {selectedAddress ? (
              <AddressUpdateForm selectedAddress={selectedAddress} cancelUpdate={() => setSelectedAddress(undefined)} />
            ) : (
              <AddressCreateForm />
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
