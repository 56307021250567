import { z } from 'zod';

export const updateAddressSchema = z.object({
  streetAddress: z.string().min(2, { message: 'validationError.ADDRESS' }),
  city: z.string().min(1, { message: 'validationError.CITY' }),
  postalCode: z.string().max(15).min(1, { message: 'validationError.ZIP_CODE' }),
  isPreferredAddress: z.boolean(),
});
export type UpdateAddressSchema = z.infer<typeof updateAddressSchema>;

export const createAddressSchema = z.object({
  streetAddress: z.string().min(2, { message: 'validationError.ADDRESS' }),
  city: z.string().min(1, { message: 'validationError.CITY' }),
  postalCode: z.string().max(15).min(1, { message: 'validationError.ZIP_CODE' }),
  isPreferredAddress: z.boolean(),
});
export type CreateAddressSchema = z.infer<typeof createAddressSchema>;
