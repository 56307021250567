import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
import { Toast, useRemoveToast, useToasts } from '../../slice/toast';

const defaultAnchorOrigin = { vertical: 'top', horizontal: 'center' } as const;
const defaultSeverity = 'info' as const;

const SharedToast = (toast: Toast) => {
  const [isOpen, setIsOpen] = useState(true);
  const removeToast = useRemoveToast();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
    removeToast(toast);
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={toast.autoHideDuration || 6000}
      onClose={handleClose}
      anchorOrigin={toast.anchorOrigin || defaultAnchorOrigin}
    >
      <Alert onClose={handleClose} severity={toast.severity || defaultSeverity} sx={{ width: '100%' }}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
};

export const SharedToasts = () => {
  const toasts = useToasts();

  return (
    <>
      {toasts.map((toast) => (
        <SharedToast key={toast.id} {...toast} />
      ))}
    </>
  );
};
