import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeValidationErrors } from '../../helpers/mergeErrors';
import { LocalNote, useCreateNoteMutation } from '../../slice/api/order';
import { CreateNoteSchema, createNoteSchema } from '../../validation/schema/order.schema';
import { SharedForm } from '../shared/SharedForm';
import { SharedTextInput } from '../shared/SharedTextInput';
import { LocalNotes } from './LocalNotes';

type LocalNoteAddProps = {
  localNotes?: LocalNote[];
  orderNumber: string;
  orderErplyId: number;
};

export const LocalNoteAdd = (props: LocalNoteAddProps) => {
  const { t } = useTranslation();
  const { localNotes, orderNumber, orderErplyId } = props;
  const [addNote, { isLoading, isSuccess, error }] = useCreateNoteMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful, isDirty },
  } = useForm<CreateNoteSchema>({
    resolver: zodResolver(createNoteSchema),
    defaultValues: { content: '', orderErplyId, orderNumber },
  });

  const onSubmit = (data: CreateNoteSchema) => {
    addNote(data);
  };

  if (isSubmitSuccessful && isSuccess) {
    reset({ content: '', orderErplyId, orderNumber });
  }

  const validationErrors = mergeValidationErrors(error, errors, t);

  return (
    <>
      <SharedForm error={error} isLoading={isLoading} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ p: 2, border: 1, borderColor: 'rgba(0,0,0,0.1)', mt: 2 }}>
          <Typography variant='h6'>{t('purchases.notes')}</Typography>
          <LocalNotes localNotes={localNotes} />
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <SharedTextInput
              fullWidth
              label={t('purchases.addNote')}
              errorMessage={validationErrors['content']}
              {...register('content')}
            />
            <Button variant='contained' color='primary' type='submit' sx={{ flexShrink: 0 }} disabled={!isDirty}>
              {t('purchases.sendNote')}
            </Button>
          </Box>
        </Box>
      </SharedForm>
    </>
  );
};
