import { Avatar, Divider, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import { useLoggedInFullUser } from '../../helpers/useLoggedInUser';
import { FullCustomer, useSwitchAccountMutation } from '../../slice/api/auth';
import { SharedMenu } from '../shared/SharedMenu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLogoutMutation } from '../../slice/api/auth';
import { Navigate } from 'react-router-dom';
import { LoadingBackdrop } from '../shared/LoadingBackdrop';
import { useTranslation } from 'react-i18next';

type HeaderMenuProps = {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  handleClose: () => void;
};

export const HeaderMenu = (props: HeaderMenuProps) => {
  const { t } = useTranslation();
  const { anchorEl, isOpen, handleClose } = props;
  const fullLoggedInState = useLoggedInFullUser();
  const [switchAccount, { isSuccess: isSuccessSwitchAccount }] = useSwitchAccountMutation();
  const customer = fullLoggedInState?.fullCustomer as FullCustomer;
  const [logout, { isLoading: isLogoutLoading, isSuccess: isSuccessLogout }] = useLogoutMutation();

  const handleLogout = () => {
    logout();
  };

  if (isSuccessSwitchAccount) {
    window.location.href = '/dashboard';
  }
  if (isSuccessLogout) {
    return <Navigate to='/login' />;
  }

  return (
    <>
      <SharedMenu anchorEl={anchorEl} isOpen={isOpen} handleClose={handleClose}>
        {customer?.companies?.map((company) => {
          const companyName = company.companyName ?? '';
          return (
            <MenuItem
              onClick={() => {
                switchAccount({ customerId: company.id });
                handleClose();
              }}
              key={company.id}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <Avatar>{companyName[0].toUpperCase()}</Avatar>
              </ListItemIcon>
              <ListItemText>{companyName}</ListItemText>
            </MenuItem>
          );
        })}
        {customer.companies.length ? <Divider /> : null}
        <MenuItem
          onClick={() => {
            switchAccount({ customerId: customer.id });
            handleClose();
          }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <Avatar>{(customer.firstName ?? 'A')[0].toUpperCase()}</Avatar>
          </ListItemIcon>
          <ListItemText>
            {customer.firstName} {customer.lastName}
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon sx={{ mr: 0.5, ml: 0.5, color: 'inherit' }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: 'inherit' }}>{t('nav.logout')}</ListItemText>
        </MenuItem>
      </SharedMenu>
      <LoadingBackdrop isLoading={isLogoutLoading} />
    </>
  );
};
