import { TableCell, TableRow, Typography } from '@mui/material';
import { OrderItem } from '../../slice/api/order';
import { useTranslation } from 'react-i18next';

type OrderDetailsRowProps = {
  item: OrderItem;
};

const rowStyle = {
  mb: -0.5,
  mt: 0,
  color: 'rgba(0,0,0,0.6)',
  display: { xs: 'block', lg: 'none' },
};

export const OrderDetailsRow = (props: OrderDetailsRowProps) => {
  const { t } = useTranslation();
  const { item } = props;

  return (
    <TableRow key={item.productCode}>
      <TableCell component='th' scope='row'>
        <Typography variant='overline' gutterBottom sx={rowStyle}>
          {t('purchases.productCode')}
        </Typography>
        <Typography variant='body1'>{item.productCode}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='overline' gutterBottom sx={rowStyle}>
          {t('purchases.productOrService')}
        </Typography>
        <Typography variant='body1'>{item.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='overline' gutterBottom sx={rowStyle}>
          {t('purchases.quantity')}
        </Typography>
        <Typography variant='body1'>{item.quantity}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='overline' gutterBottom sx={rowStyle}>
          {t('purchases.price')}
        </Typography>
        <Typography variant='body1'>{item.priceEurCent / 100}€</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='overline' gutterBottom sx={rowStyle}>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.total')}
          </Typography>
        </Typography>
        <Typography variant='body1'>{item.totalEurCent / 100}€</Typography>
      </TableCell>
    </TableRow>
  );
};
