import { Button, Container, FormGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useCompleteIdCardMutation, useStartIdCardQuery } from '../../../../slice/api/auth';
import { LoadingBackdrop } from '../../../shared/LoadingBackdrop';

const IdCardAuthWaitingForResponse = (props: { token: string; reset: () => void }) => {
  const { t } = useTranslation();
  const [completeIdCardAuth, { error, data }] = useCompleteIdCardMutation();

  useEffect(() => {
    const { abort } = completeIdCardAuth({ token: props.token });
    return () => abort();
  }, [props.token, completeIdCardAuth]);

  const restartIdCardAuth = () => {
    props.reset();
  };

  if (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessage = error.data?.message ? error.data.message : t('auth.There was a problem authenticating.');
    return (
      <Container maxWidth='sm'>
        {errorMessage}
        <Button onClick={() => restartIdCardAuth()}>{t('general.tryAgain')}</Button>
      </Container>
    );
  }

  if (data?.action === 'signup' && data?.data.pendingHash) {
    return <Navigate to={`/strong-signup?hash=${data.data.pendingHash}`} />;
  }

  return <Container maxWidth='sm'>{t('auth.Waiting for response')}</Container>;
};

export const IdCardAuth = () => {
  const { t } = useTranslation();
  const [startIdCardAuth, setStartIdCardAuth] = useState(false);
  const { isUninitialized, isLoading, isSuccess, isError, data, refetch } = useStartIdCardQuery(undefined, {
    skip: !startIdCardAuth,
  });

  if (isSuccess) {
    return <IdCardAuthWaitingForResponse token={data.token as string} reset={() => refetch()} />;
  }

  return (
    <>
      <Typography variant='h5'>{t('auth.continue with id card')}</Typography>
      {isError ? <Button onClick={() => refetch()}>{t('general.tryAgain')}</Button> : null}
      {isUninitialized ? (
        <FormGroup sx={{ mt: 3 }}>
          <Button variant='contained' color='primary' onClick={() => setStartIdCardAuth(true)}>
            {t('general.begin')}
          </Button>
        </FormGroup>
      ) : null}
      <LoadingBackdrop isLoading={isLoading} />
    </>
  );
};
