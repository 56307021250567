import { BusinessUserForm } from './BusinessUserForm';
import { PrivateUserForm } from './PrivateUserForm';

export const AccountSettingsForm = () => {
  return (
    <>
      <PrivateUserForm />
      <BusinessUserForm />
    </>
  );
};
