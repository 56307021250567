import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Container, FormGroup, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { mergeValidationErrors } from '../../../../helpers/mergeErrors';
import { useCompleteSmartIdMutation, useStartSmartIdMutation } from '../../../../slice/api/auth';
import { StartSmartIdLoginSchema, startSmartIdLoginSchema } from '../../../../validation/schema/auth.schema';
import { LoadingBackdrop } from '../../../shared/LoadingBackdrop';
import { SharedForm } from '../../../shared/SharedForm';
import { SharedTextInput } from '../../../shared/SharedTextInput';

const SmartIdWaitingForResponse = (props: { challenge: string; token: string; reset: () => void }) => {
  const { t } = useTranslation();
  const [completeSmartIdAuth, { error, data }] = useCompleteSmartIdMutation();

  useEffect(() => {
    const { abort } = completeSmartIdAuth({ token: props.token });
    return () => abort();
  }, [props.token, completeSmartIdAuth]);

  const restartSmartId = () => {
    props.reset();
  };

  if (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessage = error.data?.message ? error.data.message : t('auth.There was a problem authenticating.');
    return (
      <Container maxWidth='sm'>
        {errorMessage}
        <Button onClick={() => restartSmartId()}>{t('general.tryAgain')}</Button>
      </Container>
    );
  }

  if (data?.action === 'signup' && data?.data.pendingHash) {
    return <Navigate to={`/strong-signup?hash=${data.data.pendingHash}`} />;
  }

  return (
    <Container maxWidth='sm'>
      {t('auth.Waiting for response. Check code {{challenge}}', { challenge: props.challenge })}
    </Container>
  );
};

export const SmartIdAuth = () => {
  const { t } = useTranslation();
  const [startSmartIdAuth, { isLoading, error, data, reset, isSuccess }] = useStartSmartIdMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<StartSmartIdLoginSchema>({
    resolver: zodResolver(startSmartIdLoginSchema),
  });

  const validationErrors = mergeValidationErrors(error, errors, t);

  if (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessage = error.data?.message ? error.data.message : t('auth.There was a problem authenticating.');
    return (
      <Container maxWidth='sm'>
        {errorMessage}
        <Button onClick={() => reset()}>{t('general.tryAgain')}</Button>
      </Container>
    );
  }

  if (isSuccess && data) {
    return <SmartIdWaitingForResponse challenge={data.challenge} token={data.token} reset={reset} />;
  }

  return (
    <>
      <Typography variant='h5'>{t('auth.continue with smart ID')}</Typography>
      <br />
      <SharedForm onSubmit={handleSubmit((body) => startSmartIdAuth(body))} isLoading={isLoading}>
        <FormGroup>
          <SharedTextInput
            label={t('general.personalId')}
            errorMessage={validationErrors['idCode']}
            {...register('idCode')}
          />
        </FormGroup>

        <FormGroup sx={{ mt: 3 }}>
          <Button variant='contained' color='primary' type='submit'>
            {t('general.begin')}
          </Button>
        </FormGroup>
        <LoadingBackdrop isLoading={isLoading} />
      </SharedForm>
    </>
  );
};
