import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, TableCell, TableRow, Typography, Button, Chip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '../../slice/api/order';
import { OrderDetails } from './OrderDetails';

type OrdersTableRowProps = {
  order: Order;
};

const rowStyle = {
  mb: -0.5,
  mt: 0,
  color: 'rgba(0,0,0,0.6)',
  display: { xs: 'block', lg: 'none' },
};

export const OrdersTableRow = (props: OrdersTableRowProps) => {
  const { t } = useTranslation();
  const { order } = props;

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: 75 }}>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setIsDetailsOpen(!isDetailsOpen)}
            sx={{ display: { xs: 'none', lg: 'inline-flex' } }}
          >
            {isDetailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.orderNumber')}
          </Typography>
          <Typography variant='body1'>{order.orderNumber}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.status')}
          </Typography>
          <Chip
            label={t(`general.${order.status}`)}
            color={order.status == 'PAID' ? 'primary' : 'warning'}
            size='small'
          />
        </TableCell>
        <TableCell align='right'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.date')}
          </Typography>
          <Typography variant='body1'>{new Date(order.date).toLocaleDateString()}</Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.total')}
          </Typography>
          <Typography variant='body1'>{order.totalEurCent / 100}€</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography variant='overline' gutterBottom sx={rowStyle}>
            {t('purchases.note')}
          </Typography>
          <Typography variant='body1'>{order.notes ?? '-'}</Typography>
        </TableCell>
        <TableCell align='center' sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Button variant='contained' color='primary' onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
            {t('purchases.viewDetails')}
          </Button>
        </TableCell>
      </TableRow>
      <OrderDetails
        isOpen={isDetailsOpen}
        orderId={order.id}
        orderNumber={order.orderNumber}
        orderErplyId={order.erplyId}
        items={order.items}
        localNotes={order.localNotes}
      />
    </>
  );
};
