import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { FieldPath, FieldValues, UseFormRegister } from 'react-hook-form';

type SharedSelectProps<TFormSchema extends FieldValues> = {
  name: FieldPath<TFormSchema>;
  label: string;
  items: string[];
  itemLabels?: string[];
  register: UseFormRegister<TFormSchema>;
  errors?: Partial<Record<keyof TFormSchema, string>>;
  defaultValue?: string;
};

export const SharedSelect = <TFormSchema extends FieldValues>(props: SharedSelectProps<TFormSchema>) => {
  const { label, items, itemLabels, name, register, errors, defaultValue } = props;

  return (
    <FormControl variant='standard' sx={{ mb: 1, minWidth: 120 }} fullWidth>
      <InputLabel id='demo-simple-select-standard-label'>{label}</InputLabel>

      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        inputProps={register(name)}
        defaultValue={defaultValue ?? ''}
      >
        {items.map((item: string, idx) => (
          <MenuItem key={item} value={item}>
            {itemLabels?.[idx] ? itemLabels[idx] : item}
          </MenuItem>
        ))}
      </Select>
      {errors && errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </FormControl>
  );
};
