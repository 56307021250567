import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import OnOffLogo from '../assets/onoff-logo.svg';
import { AuthTabs } from '../component/form/auth/AuthTabs';

export const SignupScreen = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Box sx={{ pb: 4, px: 3, flexDirection: 'column', alignItems: 'flex-start' }}>
          <img src={OnOffLogo} style={{ width: '100%' }} alt='on off e-service' />
          <Typography sx={{ mt: 1, color: 'rgba(0, 0, 0, 0.9)', textTransform: 'uppercase' }}>
            {t('nav.selfService')}
          </Typography>
        </Box>
      </Container>
      <Container>
        <AuthTabs type='signup' />
        <Container maxWidth='sm' sx={{ my: 1 }}>
          <Button component={Link} variant='text' to='/login'>
            {t('auth.loginHere')}
          </Button>
        </Container>
      </Container>
    </>
  );
};
