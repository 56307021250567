import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PaginationQuery } from '../../component/shared/Pagination';
import { API_URL } from '../../config';
import { CreateNoteSchema } from '../../validation/schema/order.schema';
import { getLanguage } from '../../helpers/useSelectedLanguage';

export interface Order {
  id: number;
  erplyId: number;
  orderNumber: string;
  date: string;
  status: string;
  totalEurCent: number;
  items: OrderItem[];
  notes?: string;
  localNotes?: LocalNote[];
}

export interface OrderItem {
  id: number;
  orderId: number;
  erplyId: number;
  productCode: string;
  name: string;
  quantity: number;
  totalEurCent: number;
  priceEurCent: number;
}

export interface LocalNote {
  content: string;
  orderId: number;
  createdAt: string;
}

export const orderApiSlice = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/order`,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set('x-selected-language', getLanguage());
      return headers;
    },
  }),
  tagTypes: ['Order'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getOrders: builder.query<{ count: number; orders: Order[] }, PaginationQuery>({
      query(arg: PaginationQuery) {
        const { page, limit } = arg;
        return {
          url: `/orders`,
          method: 'GET',
          params: { page, limit },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.orders.map(({ id }) => ({
                type: 'Order' as const,
                id,
              })),
              { type: 'Order', id: 'LIST' },
            ]
          : [{ type: 'Order', id: 'LIST' }],
    }),
    createNote: builder.mutation<number, CreateNoteSchema>({
      query: (body: CreateNoteSchema) => ({
        url: '/note',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Order', id: 'LIST' }],
    }),
    getSyncTime: builder.query<string, void>({
      query: () => '/sync-time',
    }),
  }),
});

export const { useGetOrdersQuery, useCreateNoteMutation, useGetSyncTimeQuery } = orderApiSlice;
