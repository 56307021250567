import { Navigate } from 'react-router-dom';
import { useGetMyFullUserQuery } from '../../slice/api/auth';
import { LoadingBackdrop } from './LoadingBackdrop';

export const RedirectSignedIn = () => {
  const { data, isLoading, isSuccess } = useGetMyFullUserQuery();

  if (!isLoading && isSuccess && data) {
    return <Navigate to='/dashboard' />;
  }

  return <LoadingBackdrop isLoading={isLoading} />;
};
