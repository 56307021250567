import { ListItem, ListItemButton, ListItemText, IconButton } from '@mui/material';
import { Address, useDeleteAddressMutation } from '../../../slice/api/address';
import DeleteIcon from '@mui/icons-material/Delete';

type AddressRowProps = {
  isSelected: boolean;
  address: Address;
  setSelectedAddress: (address?: Address) => void;
};

export const AddressRow = (props: AddressRowProps) => {
  const { isSelected, setSelectedAddress, address } = props;
  const { streetAddress, city, postalCode, country } = address;
  const [deleteAddress] = useDeleteAddressMutation();

  const handleClick = () => {
    setSelectedAddress(isSelected ? undefined : address);
  };

  const handleDelete = () => {
    deleteAddress({ id: address.id });
  };

  return (
    <ListItem sx={{ mb: 1, p: 1, border: 1, borderColor: isSelected ? '#2FA828' : 'rgba(0,0,0,0.1)' }} >
      <ListItemButton onClick={handleClick}>
        <ListItemText
          sx={{ my: 0 }}
          primaryTypographyProps={{
            fontWeight: 'medium',
            letterSpacing: 0,
          }}
        >
          {`${streetAddress}, ${city}, ${postalCode},
            ${country}`}
        </ListItemText>
      </ListItemButton>
      <IconButton onClick={handleDelete}>
        <DeleteIcon>X</DeleteIcon>
      </IconButton>
    </ListItem>
  );
};
