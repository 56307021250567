import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Order, useGetOrdersQuery } from '../../slice/api/order';
import { Pagination } from '../shared/Pagination';
import { OrdersTableRow } from './OrdersTableRow';
import { LoadingBackdrop } from '../shared/LoadingBackdrop';

export type TableHeadCell = {
  id: string;
  numeric?: boolean;
  align?: 'inherit' | 'right' | 'center' | 'left';
  label: string;
};

export const OrdersTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  const { data, isLoading } = useGetOrdersQuery({ limit: rowsPerPage, page });

  const orders = data?.orders;

  const headCells: TableHeadCell[] = [
    {
      id: 'collapsibleArrow',
      align: 'inherit',
      label: '',
    },
    {
      id: 'productCode',
      align: 'inherit',
      label: t('purchases.orderNumber'),
    },
    {
      id: 'status',
      align: 'left',
      label: t('purchases.status'),
    },
    {
      id: 'date',
      align: 'right',
      label: t('purchases.date'),
    },
    {
      id: 'total',
      align: 'right',
      label: t('purchases.price'),
    },
    {
      id: 'note',
      align: 'center',
      label: t('purchases.note'),
    },
  ];

  return (
    <>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Typography variant='h5' sx={{ py: { xs: 1, md: 1, lg: 2 }, px: { xs: 0, md: 0, lg: 2 } }}>
            {t('purchases.orders')}
          </Typography>
          <TableContainer className='table-holder-responsive'>
            <Table aria-label='orders table'>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align}>
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.map((order: Order) => (
                  <OrdersTableRow key={`orders-table-row-${order.id}`} order={order} />
                ))}
              </TableBody>
              <Pagination
                setPage={setPage}
                page={page}
                count={data?.count ?? 0}
                rowsPerPage={rowsPerPage}
                colSpan={headCells.length}
              />
            </Table>
          </TableContainer>
          <LoadingBackdrop isLoading={isLoading} />
        </CardContent>
      </Card>
    </>
  );
};
