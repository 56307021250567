import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Card, CardContent, Grid, Divider, FormGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeValidationErrors } from '../../../helpers/mergeErrors';
import { useLoggedInUser } from '../../../helpers/useLoggedInUser';
import { useUpdateCustomerMutation } from '../../../slice/api/auth';
import {
  UpdateCustomerSchema,
  UpdatePrivateCustomerSchema,
  updatePrivateCustomerSchema,
  updatePrivateVerifiedCustomerSchema,
} from '../../../validation/schema/customer.schema';
import { ChangePasswordModal } from '../../modal/ChangePasswordModal';
import { DeleteAccountModal } from '../../modal/DeleteAccountModal';
import { SetPasswordModal } from '../../modal/SetPasswordModal';
import { SharedCheckbox } from '../../shared/SharedCheckbox';
import { SharedForm } from '../../shared/SharedForm';
import { SharedTextInput } from '../../shared/SharedTextInput';

export const PrivateUserForm = () => {
  const { t } = useTranslation();
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [isSetPasswordOpen, setIsSetPasswordOpen] = useState(false);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const user = useLoggedInUser();

  const [updateCustomer, { error: updateCustomerError, isLoading: isUpdateLoading }] = useUpdateCustomerMutation();
  const resolver = zodResolver(
    user.estonianIdVerified ? updatePrivateVerifiedCustomerSchema : updatePrivateCustomerSchema
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<UpdatePrivateCustomerSchema>({
    resolver,
    defaultValues: user,
  });

  if (user.type !== 'private') {
    return null;
  }

  const onSubmit = (data: UpdateCustomerSchema) => updateCustomer(data);

  const validationErrors = mergeValidationErrors(updateCustomerError, errors, t);

  return (
    <>
      <Grid item maxWidth='sm' xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant='h5'>{t('settings.myAccount')}</Typography>
            <br />
            <SharedForm onSubmit={handleSubmit(onSubmit)} isLoading={isUpdateLoading} error={updateCustomerError}>
              <FormGroup>
                <SharedTextInput label={t('general.email')} disabled value={user.email} />
                <br />

                <SharedTextInput
                  label={t('general.firstName')}
                  errorMessage={validationErrors['firstName']}
                  disabled={user.estonianIdVerified}
                  {...register('firstName')}
                />
                <br />

                <SharedTextInput
                  label={t('general.lastName')}
                  errorMessage={validationErrors['lastName']}
                  disabled={user.estonianIdVerified}
                  {...register('lastName')}
                />
                <br />

                <SharedTextInput
                  label={t('general.phoneNumber')}
                  errorMessage={validationErrors['phoneNumber']}
                  {...register('phoneNumber')}
                />
                <br />

                <SharedTextInput label={t('general.personalId')} value={user.estonianId ?? ''} disabled={true} />
              </FormGroup>

              <FormGroup>
                <br />
                <Divider />
                <br />

                {user.hasPassword ? (
                  <Button variant='text' onClick={() => setIsChangePasswordOpen(true)} fullWidth={false}>
                    {t('settings.changePassword')}
                  </Button>
                ) : (
                  <Button variant='text' onClick={() => setIsSetPasswordOpen(true)} fullWidth={false}>
                    {t('settings.setPassword')}
                  </Button>
                )}

                <br />
                <Divider />
                <br />
              </FormGroup>

              <FormGroup>
                <Typography variant='h6'>{t('settings.newsletter')}</Typography>

                <SharedCheckbox
                  {...register('isNewsletterAccepted')}
                  checked={watch('isNewsletterAccepted')}
                  label={t('settings.acceptNewsletter')}
                />

                <br />
                <Divider />
                <br />

                <Button variant='text' color='secondary' onClick={() => setIsDeleteAccountOpen(true)} fullWidth={false}>
                  {t('settings.deleteAccount')}
                </Button>
              </FormGroup>
              <br />
              <Divider />
              <br />

              <FormGroup>
                <Button variant='contained' color='primary' type='submit' disabled={isUpdateLoading || !isDirty}>
                  {t('general.save')}
                </Button>
              </FormGroup>
            </SharedForm>
            {user.hasPassword ? (
              <ChangePasswordModal isOpen={isChangePasswordOpen} handleClose={() => setIsChangePasswordOpen(false)} />
            ) : (
              <SetPasswordModal isOpen={isSetPasswordOpen} handleClose={() => setIsSetPasswordOpen(false)} />
            )}
            <DeleteAccountModal isOpen={isDeleteAccountOpen} handleClose={() => setIsDeleteAccountOpen(false)} />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
