import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoggedInFullUser } from '../../../helpers/useLoggedInUser';
import { useCompanyInviteAcceptMutation } from '../../../slice/api/auth';
import { LoadingBackdrop } from '../../shared/LoadingBackdrop';
import { RedirectSignedIn } from '../../shared/RedirectSignedIn';
import { Navigate } from 'react-router-dom';
import { useAddToast } from '../../../slice/toast';

export const CompanyInviteAccepted = ({ linkHash }: { linkHash: string }) => {
  const { t } = useTranslation();
  const user = useLoggedInFullUser();
  const [acceptInvitation, { error, isSuccess }] = useCompanyInviteAcceptMutation();
  const addToast = useAddToast();

  useEffect(() => {
    const { abort } = acceptInvitation({ linkHash });
    return () => abort();
  }, [acceptInvitation, linkHash]);

  if (isSuccess) {
    addToast({ message: t('auth.companyInviteSuccess'), severity: 'success' });
    return user ? <RedirectSignedIn /> : <Navigate to='/login' />;
  }

  if (error) {
    return <>{t('auth.Unable to accept invitation')}</>;
  }

  return <LoadingBackdrop isLoading={true} />;
};
