import { Grid } from '@mui/material';
import { AccountSettingsForm } from '../component/form/account/AccountSettingsForm';
import { AddressSettingsForm } from '../component/form/address/AddressSettingsForm';

export const SettingsScreen = () => {
  return (
    <>
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }} rowSpacing={4}>
        <AccountSettingsForm />
        <AddressSettingsForm />
      </Grid>
    </>
  );
};
